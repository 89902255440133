import ja from 'element-ui/lib/locale/lang/ja'
import agrEn from './agr-en'
export default {
    login: {
        loginAndRegister: '登録/ログイン',
        registerAndLogin: 'ログイン/登録',
        userLogin: 'ユーザーログイン',
        title: '携帯番号と電子メールで設定したアカウントは、それぞれ独立しています。アカウントのタイプご注意ください。',
        mobileLogin: '電話',
        emailLogin: 'メールボックス',
        email: 'E-mail',
        loginBtn: 'ログイン',
        autoLogin: '次回は自動ログインする',
        retransmission: '秒後再度送ります',
        formList: [
            {
                label: '電話番号を入力してください',
                placeholder: '確認コードを取得する',
            },
            {
                label: '確認コード',
                placeholder: '確認コードを入力してください',
            },
            {
                label: 'メールアドレスを入力してください',
            },
        ],
        btn: {
            submit: '新しいアカウントを作成する',
            title: '実際の情報は非常に重要です。慎重に記入してください',
        },
        rule: {
            phone: {
                requiredMsg: '電話番号の形式が正しくありません',
                patternMsg: '電話番号が登録されていません。新しいアカウントを作成してください',
            },
            authCode: {
                requiredMsg: '確認コードエラー、再度入力してください',
            },
            email: {
                requiredMsg: 'メールアドレスの形式が正しくありません',
                patternMsg: 'メールアドレスが登録されていません。新しいアカウントを作成してください',
            },
        },
        codeSuccess: '認証コードが送信されました。受信箱を確認してください。',
        success: '送信できました',
        error: '送信できませんでした',
        loginSuccess: 'ログインできました',
    },
    register: {
        mobileRegister: '電話',
        emailRegister: 'メールボックス',
        enroll: '携帯番号がログインされました',
        enrollEmail: '電子メールが登録されている',
        search: '検査',
        title: 'IDログイン、データレポートをクラウドストレージし、便利にチェックできる',
        needRead: {
            agree: 'を読み、同意します',
            privacy: '「ユーザープライバシー契約」',
        },
        register: 'ログイン',
        check: '「ユーザープライバシー契約」を確認してください',
        newUser: '新規ユーザーログイン',
        form: {
            labelList: ['ユーザー名', '* 性別', '* 身長', '* 年齢'],
        },
        placeholder: ['ユーザー名を入力してください', '身長を入力してください', '年齢を入力してください'],
        unit: ['SI', 'BS', '歳'],
        sexOptions: {
            male: '男性',
            female: '女性',
        },
        btn: '提出',
        rules: {
            metricheight: ['許容される身長の範囲は2ft3in-6ft7inです。再度入力してください'],
            heightMsg: ['許容される身長の範囲は70〜200cmです。再度入力してください'],
            ageMsg: ['測定可能な年齢範囲は10〜99歳ですので、再度入力してください'],
            agePointMsg: ['整数以外の数値を入力しました：年齢は整数のみです。正しい年齢を入力してください'],
        },
        success: '登録完了',
        userMessage: 'ユーザーネームを入力してください',
        verify: '4桁の確認コードを入力してください',
        errorMsg: '身長入力には小数点は許されません。再入力してください',
        abnormalMsg: '登録異常'
    },
    model: {
        loading: 'モデル生成中。少々お待ちください',
        modelLoading: 'モデル読み込み中',
        tabPane: [
            {
                title: 'ディープリアリティテクノロジー',
                msg: '体の局所的な詳細を3次元スキャンで正確に収集し、胴回り測定のミリメートルレベルの精度を確保し、フィットネス後の体型が明確に表示されます。',
            },
            {
                title: 'BDA体組成アルゴリズム',
                msg: 'Visbodyは、最先端の体組成測定システムであるBDAアルゴリズムを利用して体組成を測定します。この計算方法は、体の体積に基づいており、肥満による健康リスクをより正確に評価できます。',
            },
            {
                title: 'エラーを引き起こす要因',
                msg: '衣服が緩んでいる、体に異物が付着している場合、ある程度の誤差が発生します。最も正確なデータを取得したい場合は、タイツを着用するか、体に着る服の量を減らしてください。',
            },
            {
                title: 'レポートエントリ',
                msg: '今後レポートをチェックできるように、Webページをブックマークすることをお勧めします。このレポートを後でメールボックスに送信して保存することもできます。',
            },
        ],
        collect: ['本Webページをブックマークすることをお勧めします。', '今後レポートをチェックできるように、'],
        know: '分かりました',
        reminder: [
            'ご注意',
            '3Dモデルが生成されました。左右にスワイプして、モデルを回転させることができます。',
            'レポートが生成されました。[レポート確認]ボタンをクリックしてレポートを確認できます。',
            '姿勢評価に失敗しました。体組成レポートを確認してください。',
            '体組成評価に失敗しました。姿勢評価レポートを確認してください。',
            '測定に失敗しました。デバイスに再度測定してください〜~',
            '体組成に失敗しましたので、デバイスに再度測定してください〜~',
            '姿勢評価に失敗しました。デバイスに再度測定してください〜~',
        ],
        btn: {
            viewReport: 'レポートを確認する',
            bodyReport: '体組成レポートを確認する',
            postureReport: '姿勢レポートを確認する',
            lastReport: '前回のレポートを確認する',
            loading: '読み込み中...',
        },
    },
    report: {
        btn: {
            download: 'レポートをダウンロードする',
            send: 'レポートを送信します',
            report: 'レポート',
        },
        reportSuccess: 'レポートが生成されました',
        tabPane: {
            labelList: ['測定時間', '測定項目', '体組成', '姿勢評価', '肩の機能'],
        },
        sendEmail: 'メールボックスに送信する:',
        requiredMsg: 'メールアドレスを入力してください',
        patternMsg: '有効なメールアドレスを入力してください',
        send: '送信する',
        download: 'レポートをダウンロードする',
        sendReport: 'レポートを送信する',
    },
    sidebar: {
        successMsg: {
            sex: '性別を変更できました',
            height: '身長を変更できました',
            age: '年齢を変更できました',
        },
        logOut: 'サインアウト',
        personal: 'パーソナルセンター',
        cut: '言語/単位の切り替え',
        changeSort: '製品レポートを切り替える',
        describe: '* 製品モデルを切り替えた後、対応する製品モデルのすべての測定レポートが表示されます。',
        describeBtn: '製品モデルを切り替えた後、その製品モデルの下の最新の測定レポートが表示されます。',
        descReport: '製品レポートの切り替えができました',
        unit: ['SI (kg, cm)', 'BS (ft, in, lb)'],
        read: {
            reading: 'をお読みください',
            privacy: '「ユーザープライバシー契約」',
        },
        setting: '設定',
        form: {
            labelList: ['携帯電話番号', '単位', '言語', 'ユーザー名', '性別', '身長', '年齢'],
        },
        placeholder: ['ユーザー名を変更する', '性別の変更', '身長変更', '年齢変更'],
        btn: {
            cancelBtn: 'キャンセル',
            accomplishBtn: '終了',
            confirmBtn: '決定',
        },
        dialog: [
            '性別は体組成測定の重要な基準の1つです。変更した後測定するデータは以前のデータとは異なります。変更するかを確認してください。',
            '身長は体組成測定の重要な基準の1つです。変更した後測定するデータは以前のデータとは異なります。変更するかを確認してください。',
            '年齢は体組成測定の重要な基準の1つです。変更した後測定するデータは以前のデータとは異なります。変更するかを確認してください。',
        ],
        rules: {
            metricheight: ['範囲以内（2ft3in-6ft7in）の身長を入力してください'],
            heightMsg: ['範囲以内（70〜200cm）の身長を入力してください'],
            ageMsg: [
                '範囲以内（10〜99歳）の年齢を入力してください',
                '整数以外の数値を入力しました：年齢は整数のみです。正しい年齢を入力してください',
            ],
        },
        heightMsg: '身長を再度選択してください',
        ageMsg: '年齢を再度選択してください',
        ModelBinding: '誰かがスキャンしたようです。もう一度測定してください。',
        ReportEmpty: {
            title: '関連する測定レポートがありません',
            desc: 'Visbody 3D測定ウィザードが見つかりました、測定を行ってください！',
        },
    },
    modelBinding: {
        desc: '誰かがスキャンしたようです。もう一度測定してください。',
    },
    reportEmpty: {
        title: '関連する測定レポートがありません',
        desc: 'Visbody 3D測定ウィザードが見つかりました、測定を行ってください！',
        descS30: 'Visbody-S30 3D測定ウィザードが見つかりました、測定を行ってください！',
        descM30: 'Visbody-M30 3D測定ウィザードが見つかりました、測定を行ってください！',
    },
    userInfo: {
        height: '身長：',
        age: '年齢：',
        unit: '歳',
        weight: '体重'
    },
    mass: {
        title: '体組成評価の概要',
        titles: '体組成',
        springFrame: '',
        massFrame: '当日の測定データはありません',
        contrast: 'レポートを選らんで比較する',
        contrastReport: '比較レポートを選択する',
        noRecord: '記録なし',
        score: '点',
        status: '現在の体組成の状態',
        WT: '体重',
        PBF: '体脂肪率',
        endSymbol: '。',
        measure: {
            value: '測定値',
            standardRange: '標準範囲',
            comparedWithLastLime: '前回との比較',
            comparedNet: '前回との比較',
            current: '今回の測定点数',
            WT: '体重',
            FFM: '除脂肪体重',
            BFM: '体脂肪',
            LM: '筋肉量',
            TBW: '総水分量',
            SM: '骨格筋',
            PROTEIN: 'タンパク質',
            TM: '無機塩',
            BMR: '基礎代謝',
            WHR: 'W/H比',
            BMI: 'BMI',
            PBF: '体脂肪率',
            VFG: '内臓脂肪レベル',
            unit: 'kg',
            BMRUnit: 'kcal/d',
        },
        explain: [
            {
                title: '体重',
                msg: '体重とは身体の水分・タンパク質・無機塩及び体脂肪の合計を指します。',
            },
            {
                title: '除脂肪体重',
                msg: '除脂肪体重とは体重から脂肪を差し引いたものを指します。',
            },
            {
                title: '体脂肪',
                msg: '体脂肪とは皮下脂肪・内臓脂肪及び筋肉間脂肪の合計を指します。',
            },
            {
                title: '筋肉量',
                msg: '筋肉量は、人体の除脂肪体重、骨格筋、平滑筋、心筋で構成されている。',
            },
            {
                title: '総水分量',
                msg: '身体の水分は人体を形成する成分の中で最も多く、体重の50～70%を占めています。身体の水分は人体の細胞と体液の中に分布し、大部分が筋肉細胞の中に存在しています。',
            },
            {
                title: '骨格筋',
                msg: '骨格筋は横紋筋とも呼ばれ、骨格の筋肉に付着している筋肉の一つで、ここで計算されるのは骨格筋の含有量となります。',
            },
            {
                title: 'タンパク質',
                msg: 'タンパク質とは窒素を含む固体物質で、体内のすべての細胞に存在し、細胞の構成に関与しています。また、筋肉量の主な成分です。',
            },
            {
                title: '無機塩',
                msg: '人体は有機物/無機物/水分で構成されており、体内の無機物を無機塩とよんでいます。無機塩は身体の約5%の重量を占めています。',
            },
            {
                title: '基礎代謝',
                msg: '基礎代謝とは身体が起き安静している状況の中で、運動・植物・神経の緊張、外界温度の変化などの影響を受けない際の一日に消費する総エネルギー量のことを指します。',
            },
            {
                title: 'W/H比',
                msg: 'ウエストヒップ比とは腰囲と臀囲の比率で、中心性肥満度を判定するのに重要な指標を指します。',
            },
            {
                title: 'BMI',
                msg: 'BMIとは主に外観的肥満度を評価するのに用いられ、人間の体がどれだけ太っているか、あるいは痩せているかを示す指標として、国際的によく使われています。',
            },
            {
                title: '体脂肪率',
                msg: '体脂肪率とは脂肪が体重に占める割合を指します。',
            },
            {
                title: '内臓脂肪レベル',
                msg: '内臓脂肪の程度は、隠れ肥満かどうかを判断する重要な指標です。',
            },
            {
                title: '細胞内液',
                msg: '細胞内液：身体の細胞に含まれる体液で、原形質の基本成分です',
            },
            {
                title: '細胞外液',
                msg: '細胞外液：通常、血漿および血管と組織細胞の間の間質液を含む細胞外体液を指す',
            },
            {
                title: '代謝年齢',
                msg: '代謝年齢とは、体とその健康に関連した代謝機能のパフォーマンスを指します。',
            },
        ],
        segment: {
            fat: '局部脂肪',
            muscle: '局部筋肉',
            right: '右',
            left: '左',
            standard: '標準',
            lowStandard: '低標準',
            superStandard: '高標準',
        },
    },
    shape: {
        title: '体格評価概要',
        titles: '姿勢評価',
        status: '現在の体調',
        suggest: 'アドバイス',
        possibility: '、存在',
        models: {
            front: '正面図',
            left: '左面図',
            right: '右面図',
            top: '裏',
            low: 'やや低い',
            high: 'やや高い',
            normal: '正常',
        },
        item: {
            deviate: '測定値',
            result: '説明',
            normal: '正常',
            abnormal: '異常',
            head: '頭部の前傾',
            headSlant: '頭部の後傾',
            roundShoulderLeft: '左側巻き肩',
            roundShoulderRight: '右側巻き肩',
            highLowShoudler: 'なで肩',
            pelvis: '骨盆の前後移動',
            leftKneeCheck: '左膝の評価',
            rightKneeCheck: '右膝の評価',
            leg: '脚タイプ',
            leftLeg: '左脚：',
            rightLeg: '右脚：',
        },
        explain: [
            {
                title: '頭部の前傾',
                msg: '左側の耳の点と首の中心を結ぶ線と側面の正中線の間の夾角',
            },
            {
                title: '頭部の後傾',
                msg: '前頭部の中点と首の中心を結ぶ線と正面の中線との間の角度',
            },
            {
                title: '左側巻き肩',
                msg: '背中の左側の一番高い点を結ぶ線と肩の接線との間の夾角',
            },
            {
                title: '右側巻き肩',
                msg: '背中の右側の一番高い点を結ぶ線と肩の接線との間の夾角',
            },
            {
                title: 'なで肩',
                msg: '背中の左右の肩点間の垂直距離',
            },
            {
                title: '骨盆の前後移動',
                msg: '左側の首の中心点、寛骨点、足首点を結ぶ線の夾角',
            },
            {
                title: '左膝の評価',
                msg: '左側の寛骨点、膝関節、足首点を結ぶ三点線の夾角',
            },
            {
                title: '右膝の評価',
                msg: '右側の寛骨点、膝関節、足首点を結ぶ三点線の夾角',
            },
            {
                title: '脚タイプ',
                msg: '左右の脚の正面の寛骨点、膝関節、足首点を結ぶ線の夾角',
            },
        ],
        exception: [
            {
                title: '左に偏る',
                abnormal: '',
            },
            {
                title: '右に偏る',
                abnormal: '',
            },
            {
                title: '左が高い',
                abnormal: '',
            },
            {
                title: '右が高い',
                abnormal: '',
            },
            {
                title: '骨盤変位(後)',
                abnormal: '',
            },
            {
                title: '骨盤変位(前)',
                abnormal: '',
            },
            {
                title: '左膝過伸展',
                abnormal: '',
            },
            {
                title: '左膝の前屈',
                abnormal: '',
            },
            {
                title: '右膝過伸展',
                abnormal: '',
            },
            {
                title: '右膝の前屈',
                abnormal: '',
            },
            {
                title: 'K型レッグ',
                abnormal: '',
            },
            {
                title: 'D型レッグ',
                abnormal: '',
            },
            {
                title: 'X型レッグ',
                abnormal: '',
            },
            {
                title: 'がに股',
                abnormal: '',
            },
        ],
    },
    girth: {
        title: '各部位の周囲長',
        present: '（今回）',
        noHistory: 'データなし',
        tpl: [
            {
                title: '頸部周囲長',
                key: 'neckGirth',
            },
            {
                title: '左上腕部周囲',
                key: 'leftUpperArmGirth',
            },
            {
                title: '右上腕部周囲',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'バスト',
                key: 'bustGirth',
            },
            {
                title: 'ハイウエスト',
                key: 'waistGirth',
            },
            {
                title: 'ミッドウエスト',
                key: 'midWaistGirth',
            },
            {
                title: 'ヒップ',
                key: 'hipGirth',
            },
            {
                title: '左大腿部周囲',
                key: 'leftThighGirth',
            },
            {
                title: '左大腿中央部周囲',
                key: 'leftMidThighGirth',
            },
            {
                title: '左大腿部最小外周',
                key: 'leftMinThighGirth',
            },
            {
                title: '右大腿部周囲',
                key: 'rightThighGirth',
            },
            {
                title: '右大腿中央部',
                key: 'rightMidThighGirth',
            },
            {
                title: '右大腿部最小外周',
                key: 'rightMinThighGirth',
            },
            {
                title: '左足ふくらはぎ周長',
                key: 'leftCalfGirth',
            },
            {
                title: '右足ふくらはぎ周囲',
                key: 'rightCalfGirth',
            },
            {
                title: 'ロウウエスト',
                key: 'lowWaistGirth',
            },
        ],
    },
    shoulder: {
        title: '頸肩部機能',
        model: {
            val: '測定値',
            scope: '最大活動位置',
            diff: '前回との比較',
        },
        normal: '明らかな異常は検出されませんでした',
        normalAll: '肩の機能に明らかな異常はまだ見られません',
        conclusion: '結論',
        analyse: '分析：',
        suggest: 'アドバイス：',
        cause: '具体的な原因は専門家にさらなる検査を依頼して確認してください。',
    },
    reportTips: {
        title: 'レポートを削除したい場合は、管理者に連絡してください。',
        btnMsg: 'OK',
        alaryTips: 'このレポートは管理者により削除されました。次のレポートへジャンプします',
    },
    saveQr: {
        title: 'Visbody 3Dボディテストレポート',
        btnMsg: '画像を長く押して携帯電話に保存します。',
        tips: '保存できないことに気づいたら、【設定】に行って該当権限を開いてください。',
    },
    share: {
        title: '以下のリンクをコピーして共有してください',
        btnMsg: 'コピー',
    },
    ...ja,
    ...agrEn
}
