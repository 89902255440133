import arLocale from 'element-ui/lib/locale/lang/ar'
import agrZh from './agr-en'
export default {
    // 登录页面
    login: {
        loginAndRegister: 'التسجيل / تسجيل الدخول',
        registerAndLogin: 'التسجيل/ تسجيل الدخول',
        userLogin: 'تسجيل الدخول',
        title: `حسابات الهاتف المحمول والبريد الإلكتروني مستقلة؛
يرجى ملاحظة نوع حسابك.`,
        mobileLogin: 'هاتف',
        emailLogin: 'البريد الإلكتروني',
        email: 'ايميل',
        loginBtn: 'تسجيل الدخول',
        autoLogin: 'تذكرني',
        retransmission: 'س',
        formList: [
            {
                label: 'أدخل رقم هاتفك',
                placeholder: 'إرسال',
            },
            {
                label: 'رمز التحقق',
                placeholder: 'أدخل رمز التحقق',
            },
            {
                label: 'الرجاء إدخال بريدك الإلكتروني',
            },
        ],
        btn: {
            submit: 'انشاء حساب',
            title: 'من أجل الحصول على البيانات الدقيقة، من الضروري إدخال الطول والجنس والعمر الصحيحين.',
        },
        rule: {
            phone: {
                requiredMsg: 'تنسيق رقم الهاتف غير صحيح',
                patternMsg: 'رقم الهاتف غير مسجل ، انشاء حسابك',
            },
            authCode: {
                requiredMsg: 'رمز التحقق غير صحيح ، يرجى إعادة الكتابة',
            },
            email: {
                requiredMsg: 'تنسيق البريد الإلكتروني غير صحيح',
                patternMsg: 'البريد الإلكتروني غير مسجل، انشاء حسابك',
            },
        },
        codeSuccess: 'تم إرسال رمز التحقق، يرجى التحقق.',
        success: 'الإرسال ناجح',
        error: 'فشل الإرسال',
        loginSuccess: 'تم تسجيل الدخول بنجاح',
    },
    // 注册页面
    register: {
        mobileRegister: 'هاتف',
        emailRegister: 'البريد الإلكتروني',
        enroll: 'رقم الهاتف المحمول مسجل بالفعل',
        enrollEmail: 'البريد الإلكتروني مُسجل',
        search: 'بحث',
        title: 'الحساب الذي تم إعداده بواسطة البريد الإلكتروني ورقم الهاتف مستقل عن بعضه البعض، يرجى',
        needRead: {
            agree: 'أنا أوافق',
            privacy: '(سياسة الخصوصية)',
        },
        register: 'تسجيل الدخول',
        check: 'تحقق من " سياسة الخصوصية للمستخدم "',
        newUser: 'تسجيل الدخول',
        form: {
            labelList: ['الأسم', '* الجنس', '* الطول', '* العمر'],
        },
        placeholder: ['الرجاء إدخال اسمك', 'الرجاء إدخال طولك', 'الرجاء إدخال عمرك'],
        unit: ['متري', 'امبراطوري', ''],
        sexOptions: {
            male: 'ذكر',
            female: 'أنثى',
        },
        btn: 'تقديم',
        rules: {
            metricheight: [`نطاق الطول هو 2'3'' - 6'7'', يرجى إدخاله مرة أخرى.`],
            heightMsg: [`نطاق الطول هو 70 - 200 سم، يرجى إدخاله مرة أخرى.`],
            ageMsg: ['نطاق العمر هو 10 - 99، يرجى إدخاله مرة أخرى.'],
            agePointMsg: ['تم تعبئة غير عدد صحيح: يمكن أن يكون العمر فقط عدد صحيح، يرجى تعبئة العمر الصحيح'],
        },
        success: 'التسجيل ناجح',
        userMessage: 'أدخل اسم المستخدم',
        verify: 'أدخل رمز التحقق المكون من 4 أرقام',
        errorMsg: 'تنسيق الارتفاع لا يدعم الكسور العشرية ، الرجاء إعادة الإدخال',
        abnormalMsg: 'تسجيل غير طبيعي'
    },
    // 模型合成
    model: {
        loading: 'جار التحميل ... الرجاء الانتظار لحظة',
        modelLoading: 'تحميل نموذج ثلاثي الأبعاد',
        tabPane: [
            {
                title: 'تكنولوجيا الإحساس العميق الحقيقية',
                msg: 'جمع تفاصيل محلية دقيقة عن الجسم من خلال المسح الضوئي 3D، لضمان دقة مستوى المليمتر في قياس الحجم، وعرض تغييرات شكل الجسم بوضوح بعد التمرين.',
            },
            {
                title: 'خوارزمية BDA لتركيبة الجسم',
                msg: 'تستخدم Visbody أكثر نظام قياس لتركيبة الجسم تقدمًا - خوارزمية BDA لتحديد تركيبة الجسم. ستقيم هذه الطريقة الحسابية التي تعتمد على حجم الجسم المخاطر الصحية التي يجلبها السمنة بشكل أكثر دقة',
            },
            {
                title: 'العوامل التي تسببت في الأخطاء',
                msg: 'الملابس الفضفاضة أو بعض الزينة على / في الجسم ستسبب أخطاء ، يرجى ارتداء الملابس الضيقة أو تقليل كمية الملابس على جسمك إذا كنت ترغب في الحصول على البيانات الأكثر دقة.',
            },
            {
                title: 'احصل على تقريرك',
                msg: 'يُنصح بتعليم صفحة الويب هذه لسهولة الاطلاع على التقرير في المستقبل. يمكنك أيضًا إرسال هذا التقرير إلى صندوق بريدك الإلكتروني لحفظه لاحقًا.',
            },
        ],
        collect: ['يرجى تعليم هذه الصفحة ، سهلة لمراجعة التقرير في المستقبل.', ''],
        know: 'فهمت',
        reminder: [
            'الرجاء التذكير',
            'تم إنشاء نموذج الأفاتار 3D الخاص بك ، اسحب إلى اليسار واليمين لتدوير النموذج.',
            'تم إنشاء تقريرك ويمكن الاطلاع عليه بالنقر على زر "عرض التقرير".',
            'تعذر تقييم جسمك، يُرجى مراجعة تقرير تركيب الجسم.',
            'تعذر تركيب الجسم الخاص بك، يُرجى مراجعة تقرير تقييم الوضعية.',
            'فشلت القياسات الخاصة بك، يُرجى الذهاب إلى الجهاز وإجراء الاختبار مرة أخرى.',
            'تعذر قياس تركيبة الجسم، يُرجى الذهاب إلى الجهاز وإجراء الاختبار مرة أخرى.',
            'فشل تقييم الوضعية، يُرجى الذهاب إلى الجهاز وإجراء الاختبار مرة أخرى.',
        ],
        btn: {
            viewReport: 'عرض التقرير',
            bodyReport: 'عرض تقرير تركيبة الجسم',
            postureReport: 'عرض تقرير وضعية الجسم',
            lastReport: 'عرض آخر تقرير',
            loading: 'جارٍ التحميل',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'تنزيل التقرير',
            send: 'إرسال التقرير',
            report: 'تقرير',
        },
        reportSuccess: 'نجاح!',
        tabPane: {
            labelList: ['وقت القياس', 'عنصر', 'تركيب الجسم', 'تقييم الوضعية', 'وظائف الكتف'],
        },
        sendEmail: 'البريد الإلكتروني:',
        requiredMsg: 'أدخل عنوان بريدك الإلكتروني',
        patternMsg: 'يرجى إدخال عنوان بريد إلكتروني صالح',
        send: 'إرسال',
        download: 'تنزيل التقرير',
        sendReport: 'إرسال التقرير',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'تم تغيير الجنس بنجاح',
            height: 'تم تغيير الطول بنجاح',
            age: 'تم تغيير العمر بنجاح',
        },
        logOut: 'تسجيل الخروج',
        personal: 'المركز الشخصي',
        cut: 'تغيير الوحدات / اللغات',
        changeSort: 'تبديل تقرير المنتج',
        describe: '* بعد تبديل نموذج المنتج، ستظهر جميع تقارير القياس تحت هذا النموذج.',
        describeBtn: 'بعد تبديل نموذج المنتج، ستتم إعادة توجيهك إلى أحدث تقرير قياس تحت هذا النموذج.',
        descReport: 'تم تغيير تقرير المنتج بنجاح',
        unit: ['متري (كجم، سم)', 'إمبراطوري (قدم, بوصة, رطل)'],
        read: {
            reading: 'اقرأ',
            privacy: '(سياسة الخصوصية)',
        },
        setting: 'الإعدادات',
        form: {
            labelList: ['الهاتف', 'الوحدات', 'اللغات', 'الاسم', 'الجنس', 'الطول', 'العمر'],
        },
        placeholder: ['تغيير اسم المستخدم', 'جنس جديد', 'تحديث الطول', 'تحديث العمر'],
        btn: {
            cancelBtn: 'إلغاء',
            accomplishBtn: 'إكمال',
            confirmBtn: 'تأكيد',
        },
        dialog: [
            'الجنس هو أحد الأساسات المهمة لقياس تركيبة الجسم. ستختلف البيانات المقاسة بعد التعديل عن البيانات السابقة. هل ترغب في تغييرها؟',
            'الطول هو أحد الأساسات المهمة لقياس تركيبة الجسم. ستختلف البيانات المقاسة بعد التعديل عن البيانات السابقة. هل ترغب في تغييرها؟',
            'العمر هو أحد الأساسات المهمة لقياس تركيبة الجسم. ستختلف البيانات المقاسة بعد التعديل عن البيانات السابقة. هل ترغب في تغييرها؟',
        ],
        rules: {
            metricheight: [`يرجى ملء النطاق الصحيح للطول (2'3 "إلى 6'7").`],
            heightMsg: ['يرجى ملء الطول ضمن 70 سم - 200 سم'],
            ageMsg: ['يرجى ملء العمر ضمن 10 - 99 سنة.', 'تم تعبئة غير صحيحة، يمكن أن يكون العمر فقط عدد صحيح، يرجى ملء العمر الصحيح.'],
        },
        heightMsg: 'الرجاء إعادة اختيار الطول',
        ageMsg: 'الرجاء إعادة اختيار العمر',
        ModelBinding: 'يبدو أن شخصًا ما قد قام بالفحص بالفعل، يرجى قياس مرة أخرى!',
        ReportEmpty: {
            title: 'ليس لديك أي تقرير حتى الآن.',
            desc: 'تعال إلى ماسح الجسم ثلاثي الأبعاد Visbody واختبر مرة أخرى!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'يبدو أن شخصًا ما قد قام بالفحص بالفعل، يرجى قياس مرة أخرى!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: 'ليس لديك أي تقرير حتى الآن.',
        desc: 'تعال إلى ماسح الجسم ثلاثي الأبعاد Visbody واختبر مرة أخرى!',
        descS30: 'تعال إلى ماسح الجسم ثلاثي الأبعاد Visbody واختبر مرة أخرى!',
        descM30: 'تعال إلى ماسح الجسم ثلاثي الأبعاد Visbody واختبر مرة أخرى!',
    },
    // 用户信息
    userInfo: {
        height: 'الطول:',
        age: 'العمر:',
        unit: '',
        weight: 'الوزن'
    },
    // 体成分
    mass: {
        title: 'نظرة عامة على تقييم تركيبة الجسم',
        titles: 'تركيب الجسم',
        springFrame: '',
        massFrame: 'لا توجد بيانات قياس لهذا اليوم',
        contrast: 'اختر تقريرًا للمقارنة',
        contrastReport: 'اختر تقرير للمقارنة',
        noRecord: 'لا توجد سجلات',
        score: 'النتيجة',
        status: 'حالة تركيبة جسمك الحالية',
        WT: 'الوزن',
        PBF: 'نسبة الدهون في الجسم',
        endSymbol: '.',
        // 测量相关
        measure: {
            value: 'قيمة القياس',
            standardRange: 'المجال القياسي',
            comparedWithLastLime: 'الصافي',
            comparedNet: 'مقارنة بالنتيجة الأخيرة',
            current: '本次测量分数',
            WT: 'الوزن',
            FFM: 'الكتلة الجسمية الرشيقة',
            BFM: 'كتلة الدهون في الجسم',
            LM: 'كتلة العضلات',
            TBW: 'الماء في الجسم',
            SM: 'SMM (كتلة العضلات الهيكلية)',
            PROTEIN: 'البروتين',
            TM: 'الأملاح غير العضوية',
            BMR: 'معدل الأيض الأساسي',
            WHR: 'WHR (نسبة الخصر إلى الورك)',
            BMI: 'BMI',
            PBF: 'BFP (نسبة الدهون في الجسم)',
            VFG: 'مستوى الدهون الحشوية',
            unit: 'كجم',
            BMRUnit: 'كيلو كالوري / يوم',
        },
        // 定义解读
        explain: [
            {
                title: 'الوزن',
                msg: 'الوزن هو مجموع ماء الجسم والبروتين والأملاح غير العضوية ووزن الجسم.',
            },
            {
                title: 'الكتلة الجسمية الرشيقة',
                msg: 'الكتلة الجسمية الرشيقة هي الوزن الجسمي الإجمالي دون الدهون.',
            },
            {
                title: 'كتلة الدهون في الجسم',
                msg: 'كتلة الدهون في الجسم هي مجموع الدهون تحت الجلد والدهون الحشوية ودهون العضلات.',
            },
            {
                title: 'كتلة العضلات',
                msg: 'الكتلة العضلية الرشيقة هي الكتلة الجسمية الرشيقة، والتي تشمل العضلات الهيكلية والعضلات الملساء وعضلة القلب.',
            },
            {
                title:'الماء في الجسم',
                msg: 'معظم الجسم البشري هو الماء بكمية تتراوح بين 50%-70% من وزن الجسم. ويوجد ماء الجسم أساسًا في خلايا الجسم البشري والسوائل الجسدية ، معظمها في خلايا العضلات.',
            },
            {
                title: 'SMM',
                msg: 'كتلة العضلات الهيكلية، المعروفة أيضا باسم العضلات المشطية، هي نوع من العضلات المثبتة على العظام. هذه البيانات تحتوي على كمية العضلات الهيكلية.',
            },
            {
                title: 'البروتين',
                msg: 'البروتين هو مادة صلبة مع الأمونيا، والتي توجد في جميع خلايا الجسم البشري. إنه المكون الرئيسي لكتلة العضلات.',
            },
            {
                title: 'الأملاح غير العضوية',
                msg: 'الجسم البشري يتكون من المادة العضوية والمادة غير العضوية والماء. المادة غير العضوية هنا هي الأملاح غير العضوية التي تشكل 5% من وزن الجسم.',
            },
            {
                title: 'معدل الأيض الأساسي',
                msg: 'معدل الأيض الأساسي هو الطاقة الإجمالية المستهلكة في يوم واحد عندما يكون الجسم في حالة راحة، غير متأثر بالتمرين، الأشياء الفيزيائية، التوتر، التغييرات في در',
            },
            {
                title: 'WHR (نسبة الخصر إلى الورك)',
                msg: 'معدل الخصر إلى محيط الورك، هو مؤشر مهم لتحديد السمنة المركزية.',
            },
            {
                title: 'BMI',
                msg: 'يستخدم الٌBMI بشكل رئيسي لتقييم ظهور السمنة، وهو المعيار المشترك لقياس تراكم الدهون في الجسم.',
            },
            {
                title: 'BFP (نسبة الدهون في الجسم)',
                msg: 'الٌBFP هو قياس لتركيب الجسم يخبرنا عن كمية الوزن التي تشكل الدهون في الجسم.',
            },
            {
                title: 'مستوى الدهون الحشوية',
                msg: 'الدهون الحشوية هي مؤشر مهم لتقييم السمنة المخفية.',
            },
            {
                title: 'ICW',
                msg: 'ICW: هو السائل الجسدي المحتوي في خلايا الجسم وهو المكون الأساسي للبروتوبلازم.',
            },
            {
                title: 'ECW',
                msg: 'ECW: يُشير عادة الى السوائل الجسدية خارج الخلية، بما في ذلك البلازما والسائل الخلوي بين الأوعية الدموية وخلايا الأنسجة.',
            },
            {
                title: 'العمر الأيضي',
                msg: 'العمر الأيضي يشير إلى أداء الجسم ووظائفه الصحية المتعلقة بالأيض.',
            },
        ],
        // 节段
        segment: {
            fat: 'تحليل الدهون القطاعي',
            muscle: 'تحليل العضلات القطاعي',
            right: 'يمين',
            left: 'يسار',
            standard: 'طبيعي',
            lowStandard: 'أقل من',
            superStandard: 'أكثر من',
        },
    },
    // 体态
    shape: {
        title: 'نظرة عامة على تقييم الجسم',
        titles: 'تقييم الوضعية',
        status: 'حالتك البدنية الحالية',
        suggest: 'اقتراح',
        possibility: '، هناك إمكانية لـ',
        models: {
            front: 'أمام',
            left: 'جانب اليسار',
            right: 'جانب اليمين',
            top: 'الى الوراء',
            low: 'تحت',
            high: 'فوق',
            normal: 'عادي',
        },
        item: {
            deviate: 'قيمة القياس',
            result: 'شرح',
            normal: 'عادي',
            abnormal: 'غير طبيعي',
            head: 'وضعية الرأس المتقدمة',
            headSlant: 'ميل الرأس',
            roundShoulderLeft: 'وضعية الكتفين المستديرة (الجانب الأيسر)',
            roundShoulderRight: 'وضعية الكتفين المستديرة (الجانب الأيمن)',
            highLowShoudler: 'الكتفين غير متساويين',
            pelvis: 'الحوض للأمام / ازاحة الحوض للخلف',
            leftKneeCheck: 'تقييم الركبة اليسرى',
            rightKneeCheck: 'تقييم الركبة اليمنى',
            leg: 'شكل الساق',
            leftLeg: 'الساق اليسرى:',
            rightLeg: 'الساق اليمنى:',
        },
        // 定义解读
        explain: [
            {
                title: 'وضعية الرأس المتقدمة',
                msg: 'الزاوية بين نقطة الأذن على الجانب الأيسر والخط الذي يربط مركز الرقبة والخط الوسطي على الجانب',
            },
            {
                title: 'ميل الرأس',
                msg: 'الزاوية بين منتصف الرأس الأمامي ومركز العنق وخط الوسط الأمامي',
            },
            {
                title: 'وضعية الكتفين المستديرة (الجانب الأيسر)',
                msg: 'الزاوية بين خط يربط أعلى نقطة على الجانب الأيسر من الظهر وخط التماس للكتف',
            },
            {
                title: 'وضعية الكتفين المستديرة (الجانب الأيمن)',
                msg: 'الزاوية بين خط يربط أعلى نقطة على الجانب الأيمن من الظهر وخط التماس للكتف',
            },
            {
                title: 'الكتفين غير متساويين',
                msg: 'المسافة العمودية بين نقطة الكتف اليسرى واليمنى على الظهر',
            },
            {
                title: 'الحوض للأمام / ازاحة الحوض للخلف',
                msg: 'الزاوية المشتملة للخط الذي يربط نقطة مركز العنق ونقطة العظم الوركي ونقطة الكاحل على الجانب الأيسر',
            },
            {
                title: 'تقييم الركبة اليسرى',
                msg: 'الزاوية المشتملة للخط الثلاثي النقاط الذي يربط نقطة العظم الوركي والمفصل الركبة ونقطة الكاحل على الجانب الأيسر',
            },
            {
                title: 'تقييم الركبة اليمنى',
                msg: 'الزاوية المشتملة للخط الثلاثي النقاط الذي يربط نقطة العظم الوركي والمفصل الركبة ونقطة الكاحل على الجانب الأيمن',
            },
            {
                title: 'شكل الساق',
                msg: 'الزاوية المشتملة للخط الذي يربط نقطة العظم الوركي والمفصل الركبة ونقطة الكاحل على الجهة الأمامية من الساقين اليسرى واليمنى',
            },
        ],
        exception: [
            {
                title: 'مائل إلى الجانب الأيسر',
                abnormal: '',
            },
            {
                title: 'مائل إلى الجانب الأيمن',
                abnormal: '',
            },
            {
                title: 'العالي الجانب الأيسر',
                abnormal: '',
            },
            {
                title: 'العالي الجانب الأيمن',
                abnormal: '',
            },
            {
                title: 'ميل الحوض الخلفي',
                abnormal: '',
            },
            {
                title: 'ميل الحوض الأمامي',
                abnormal: '',
            },
            {
                title: 'تمدد زائد للركبة اليسرى',
                abnormal: '',
            },
            {
                title: 'انحناء الركبة اليسرى إلى الأمام',
                abnormal: '',
            },
            {
                title: 'تمدد زائد للركبة اليمنى',
                abnormal: '',
            },
            {
                title: 'انحناء الركبة اليمنى إلى الأمام',
                abnormal: '',
            },
            {
                title: 'أرجل بشكل K',
                abnormal: '',
            },
            {
                title: 'أرجل بشكل D',
                abnormal: '',
            },
            {
                title: 'أرجل بشكل X',
                abnormal: '',
            },
            {
                title: 'أرجل بشكل O',
                abnormal: '',
            },
        ],
    },
    // 围度信息
    girth: {
        title: 'المحيطات',
        present: '(الحاضر)',
        noHistory: 'لا يوجد بيانات',
        tpl: [
            {
                title: 'محيط الرقبة',
                key: 'neckGirth',
            },
            {
                title: 'الذراع العلوي الأيسر',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'الذراع العلوي الأيمن',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'الصدر',
                key: 'bustGirth',
            },
            {
                title: 'الخصر العالي',
                key: 'waistGirth',
            },
            {
                title: 'الخصر الأوسط',
                key: 'midWaistGirth',
            },
            {
                title: 'الورك',
                key: 'hipGirth',
            },
            {
                title: 'الفخذ الأيسر',
                key: 'leftThighGirth',
            },
            {
                title: 'الفخذ الأيسر في المنتصف',
                key: 'leftMidThighGirth',
            },
            {
                title: 'أدنى محيط للفخذ الأيسر',
                key: 'leftMinThighGirth',
            },
            {
                title: 'محيط الفخذ الأيمن',
                key: 'rightThighGirth',
            },
            {
                title: 'الفخذ الأيمن في المنتصف',
                key: 'rightMidThighGirth',
            },
            {
                title: 'أدنى محيط للفخذ الأيمن',
                key: 'rightMinThighGirth',
            },
            {
                title: 'محيط ساق اليسرى',
                key: 'leftCalfGirth',
            },
            {
                title: 'محيط ساق اليمنى',
                key: 'rightCalfGirth',
            },
            {
                title: 'الخصر الدنيء',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'وظائف الكتف',
        model: {
            val: 'قيمة القياس',
            scope: 'الموقع الأكثر نشاطاً',
            diff: 'النت',
        },
        normal: 'لم يتم اكتشاف أي شذوذ بارز.',
        normalAll: 'لا توجد شذوذ واضحة في وظيفة الكتف حتى الآن.',
        conclusion: 'الخلاصة',
        analyse: 'التحليل: ',
        suggest: 'الاقتراح: ',
        cause: 'يرجى طلب النصيحة من الخبراء للحصول على التفاصيل.',
    },
    reportTips: {
        title: 'يرجى الاتصال بالمشرف إذا كنت ترغب في حذف التقرير.',
        btnMsg: 'موافق',
        alaryTips: 'تم حذف هذا التقرير بواسطة المدير. سيتم القفز إلى التقرير التالي!',
    },
    saveQr: {
        title: 'تقرير ماسح ضوئي Visbody 3D للجسم',
        btnMsg: 'اضغط مطولاً على الصورة لحفظها في هاتفك.',
        tips: 'إذا وجدت أنك لا تستطيع الحفظ، يرجى الانتقال إلى [الإعدادات] وفتح الأذونات المناسبة.',
    },
    vanCalendar: {
        end: 'End',
        start: 'Start',
        title: 'Calendar',
        startEnd: 'Start/End',
        weekdays: ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
        monthTitle: function monthTitle(year, month) {
          return month + "/" + year;
        },
        rangePrompt: function rangePrompt(maxRange) {
          return "Choose no more than " + maxRange + " days";
        }
      },
    share: {
        title: 'الرجاء نسخ الرابط أدناه ومشاركته',
        btnMsg: 'نسخ',
    },
    ...arLocale,
    ...agrZh
}
