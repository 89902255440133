/*
 * @Description: Description
 * @Author: dpw
 * @Date: 2023-12-09 13:58:43
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-08-29 17:21:51
 */
import ruLocale from 'element-ui/lib/locale/lang/ru-RU'
import agrEn from './agr-en'
export default {
    // 登录页面
    login: {
        loginAndRegister: 'Регистрация / Вход',
        registerAndLogin: 'Регистрация / Вход',
        userLogin: 'Войти',
        title: 'Аккаунт, регистрируемый по номеру телефона и электронному  почтовому адресу, являются независимыми друг от друга',
        mobileLogin: 'Тел.',
        emailLogin: 'Эл.почта',
        email: 'E-mail',
        loginBtn: 'Войти',
        autoLogin: 'Запомнить меня',
        retransmission: 'с',
        formList: [
            {
                label: 'Введите свой телефонный номер',
                placeholder: 'Отправить',
            },
            {
                label: 'авторизация',
                placeholder: 'Введите код',
            },
            {
                label: 'Введите email',
            },
        ],
        btn: {
            submit: 'Создать аккаунт',
            title: 'В целях  получения более верных данных, важно указать точные вес, пол и возраст',
        },
        rule: {
            phone: {
                requiredMsg: 'Неверный  формат номера телефона',
                patternMsg: 'Номер  телефона  не зарегистрирован, создайте  аккаунт',
            },
            authCode: {
                requiredMsg: 'Неверный  код авторизации, пожалуйста, введите код заново',
            },
            email: {
                requiredMsg: 'Неверный формат электронной почты',
                patternMsg: 'Электронная почта не зарегистрирована, создайте аккаунт',
            },
        },
        codeSuccess: 'Код подтверждения был отправлен, пожалуйста, проверьте.',
        success: 'Успешно отправлено',
        error: 'Ошибка отправки',
        loginSuccess: 'Успешная авторизация',
    },
    // 注册页面
    register: {
        mobileRegister: 'Тел.',
        emailRegister: 'Эл.почта',
        enroll: 'Данный номер уже зарегистрирован',
        enrollEmail: 'Электронная  почта зарегистрирована',
        search: 'Поиск',
        title: 'Аккаунт, регистрируемый по номеру телефона и электронному  почтовому адресу, являются независимыми друг от друга',
        needRead: {
            agree: 'Я ознакомился и согласен',
            privacy: 'Политика конфиденциальности',
        },
        register: 'Регистрация',
        check: 'Ознакомлен с  “Политикой конфиденциальности”',
        newUser: 'Регистрация нового пользователя',
        form: {
            labelList: ['Имя', '* Пол', '* Рост', '* Возраст'],
        },
        placeholder: ['Пожалуйста, введите имя', 'Введите рост', 'Пожалуйста, введите возраст'],
        unit: ['Метрическая', 'Имперская', ''],
        sexOptions: {
            male: 'МУЖ',
            female: 'ЖЕН',
        },
        btn: 'Отправить',
        rules: {
            metricheight: ["Диапазон роста составляет от 2'3'' до 6'7'', пожалуйста, введите  заново."],
            heightMsg: ['Диапазон роста составляет от 70 до 200 см, пожалуйста, введите  заново.'],
            ageMsg: ['Возрастной диапазон составляет от 10 до 99 лет, пожалуйста,  введите заново.'],
            agePointMsg: ['Введено нецелое число: возраст может быть только целым числом,  пожалуйста, введите правильный возраст.'],
        },
        success: 'Успешная регистрация',
        userMessage: 'Введите имя пользователя',
        verify: 'Введите 4х значный код верификации',
        errorMsg: 'Формат данных роста не поддерживает знаки после запятой,  пожалуйста введите заного',
        abnormalMsg: 'Аномалия регистрации'
    },
    // 模型合成
    model: {
        loading: 'Загрузка…Пожалуйста подождите',
        modelLoading: 'Загрузка 3D модели',
        tabPane: [
            {
                title: 'Технология Deep Real Sense',
                msg: 'Точно собирает локальные детали тела с помощью 3D-сканирования,  обеспечивает миллиметровую точность измерения окружности и четко представляет  изменения формы тела после тренировки.',
            },
            {
                title: 'Алгоритм BDA состава тела',
                msg: 'Visbody  использует самую передовую систему измерения состава тела - алгоритм BDA для  определения состава тела. Этот метод расчета, основанный на объеме тела,  более точно оценит  риски для здоровья,  вызванные ожирением.',
            },
            {
                title: 'Факторы, вызывающие ошибки',
                msg: 'Свободная одежда или некоторые украшения на теле могут вызвать  ошибки, пожалуйста, надевайте обтягивающую одежду или уменьшайте количество  одежды на вашем теле, если вы хотите получить самые точные данные.',
            },
            {
                title: 'Получите ваш отчет',
                msg: 'Рекомендуется  добавить URL веб-страницы в закладки для удобного просмотра отчета в будущем.  Вы также можете отправить этот отчет на свою электронную почту, чтобы  сохранить его позже.',
            },
        ],
        collect: ['Пожалуйста, добавьте эту страницу в закладки, Легко просматривать отчет в будущем.', ''],
        know: 'Ясно',
        reminder: [
            'Напоминаем',
            'Ваша 3D-модель аватара создана, проведите влево и вправо, чтобы  повернуть модель.',
            "Ваш отчет был создан и его можно просмотреть, нажав кнопку  'Просмотреть отчет'.",
            'Оценка вашего тела не удалась, пожалуйста, просмотрите отчет о  составе тела.',
            'Оценка состава вашего тела не удалась, пожалуйста, просмотрите  отчет об оценке осанки.',
            'Ваше измерение не удалось, пожалуйста, перейдите к устройству и  повторите тест.',
            'Измерение состава тела не удалось, пожалуйста, перейдите к  устройству и повторите тест.',
            'Оценка осанки не удалась, пожалуйста, перейдите к устройству и  повторите тест.',
        ],
        btn: {
            viewReport: 'Просмотреть отчет',
            bodyReport: 'Просмотреть  отчет о составе тела',
            postureReport: 'Просмотреть отчет об осанке тела',
            lastReport: 'Просмотреть последний отчет',
            loading: 'Загрузка',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'Скачать отчет',
            send: 'Отправить отчет',
            report: 'Отчет',
        },
        reportSuccess: 'Отчёт успешно создан',
        tabPane: {
            labelList: ['Время измерения', 'Тип измерения', 'Состав тела', 'Осанка тела', 'Функция  плеча'],
        },
        sendEmail: 'Отправить на эл.почту:',
        requiredMsg: 'Введите адрес эл.почты',
        patternMsg: 'Введите правильный email',
        send: 'Отправить',
        download: 'Скачать отчет',
        sendReport: 'Отправить отчет',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'Успешная смена пола',
            height: 'Успешная смена роста',
            age: 'Успешная смена возраста',
        },
        logOut: 'Выйти',
        personal: 'Личный кабинет',
        cut: 'Сменить единицы измерения/язык',
        changeSort: 'Переключение отчета о продукте',
        describe: 'После  переключения модели продукта, будут отображаться все отчеты об измерениях для  этой модели.',
        describeBtn: 'После  переключения модели продукта, вы будете перенаправлены к последнему отчету об  измерениях для этой модели.',
        descReport: 'Успешное переключение отчета о продукте',
        unit: ['Метрическая (кг,см)', 'Имперская (фут,дюйм,фунт)'],
        read: {
            reading: 'Прочитать',
            privacy: 'Политика конфиденциальности',
        },
        setting: 'Настройки',
        form: {
            labelList: ['Тел.', 'Единица', 'Языки', 'Имя', 'Пол', 'Рост', 'Возраст'],
        },
        placeholder: ['Изменить имя пользователя', 'Изменить пол', 'Изменить рост', 'Изменить возраст'],
        btn: {
            cancelBtn: 'Отмена',
            accomplishBtn: 'Закончить',
            confirmBtn: 'Подвердить',
        },
        dialog: [
            'Пол является  одним из важных критериев для измерения состава тела. Данные, измеренные  после изменения, будут отличаться от предыдущих данных. Вы уверены, что  хотите его изменить?',
            'Рост  является одним из важных критериев для измерения состава тела. Данные,  измеренные после изменения, будут отличаться от предыдущих данных. Вы  уверены, что хотите его изменить?',
            'Возраст  является одним из важных критериев для измерения состава тела. Данные,  измеренные после изменения, будут отличаться от предыдущих данных. Вы  уверены, что хотите его изменить?',
        ],
        rules: {
            metricheight: [`Пожалуйста, заполните правильный диапазон роста (от 2'3" до  6'7")`],
            heightMsg: [`Пожалуйста, заполните рост в пределах 70 см - 200 см`],
            ageMsg: [`Пожалуйста, заполните возраст в пределах 10 - 99 лет.`],
        },
        heightMsg: 'Пожалуйста, выберите рост заново',
        ageMsg: 'Пожалуйста, выберите возраст заново',
        ModelBinding: 'Кажется,  кто-то уже отсканировал код, пожалуйста, пройдите тестирование снова!',
        ReportEmpty: {
            title: 'У вас еще нет ни одного отчета',
            desc: 'Приходите к 3D сканеру тела Visbody и пройдите тест снова!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'Кажется,  кто-то уже отсканировал код, пожалуйста, пройдите тестирование снова!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: 'У вас еще нет ни одного отчета',
        desc: 'Приходите к 3D сканеру тела Visbody и пройдите тест снова!',
        descS30: 'Приходите к 3D сканеру тела Visbody-S30 и пройдите тест снова!',
        descM30: 'Приходите к 3D сканеру тела Visbody-M30 и пройдите тест снова!',
    },
    // 用户信息
    userInfo: {
        height: 'Рост:',
        age: 'Возраст:',
        unit: '',
        weight: 'Вес'
    },
    // 体成分
    mass: {
        title: 'Обзор оценки состава тела',
        titles: 'Состав тела',
        springFrame: '',
        massFrame: 'Нет данных измерений за день',
        contrast: 'Выберите отчет для сравнения',
        contrastReport: 'Выберите отчет для сравнения',
        noRecord: 'Нет записей',
        score: 'Оценка',
        status: 'Ваш текущий статус состава тела',
        WT: 'Вес',
        PBF: 'Процент жира в организме',
        endSymbol: '。',
        // 测量相关
        measure: {
            value: 'Значения',
            standardRange: 'Стандартный диапазон',
            comparedWithLastLime: 'Сеть',
            comparedNet: 'В сравнении с  последней оценкой',
            current: '本次测量分数',
            WT: 'Вес',
            FFM: 'Сухая масса тела',
            BFM: 'Масса жира в теле',
            LM: 'Масса мышц',
            TBW: 'Вода в организме',
            SM: 'МСМ (Масса скелетных мышц)',
            PROTEIN: 'Белки',
            TM: 'Неорганические соли',
            BMR: 'Базальный метаболизм',
            WHR: 'WHR (Соотношение талии к бедрам)',
            BMI: 'ИМТ (Индекс массы тела)',
            PBF: 'Процент жира в теле',
            VFG: 'Уровень висцерального жира',
            unit: 'кг',
            BMRUnit: 'ккал/д',
        },
        // 定义解读
        explain: [
            {
                title: 'Вес',
                msg: 'Вес - это  сумма воды в организме, белка, неорганической соли и массы тела.',
            },
            {
                title: 'Сухая масса тела',
                msg: 'Сухая масса тела - это общий вес тела без жира',
            },
            {
                title: 'Масса жира в теле',
                msg: 'Масса жира в организме - это сумма подкожного жира,  висцерального жира и мышечного жира.',
            },
            {
                title: 'Масса мышц',
                msg: 'Мягкие нежировые ткани - сухая  масса тела, которая включает скелетные мышцы, гладкую мускулатуру и сердечные  мышцы.',
            },
            {
                title: 'Вода в организме',
                msg: 'Большая часть человеческого тела - это вода, составляющая  50%-70% от массы тела. И вода в организме в основном находится в клетках  человека и жидкостях организма, большая часть которой находится в мышечных  клетках.',
            },
            {
                title: 'МСМ (Масса скелетных мышц)',
                msg: 'Скелетно-мышечная  масса, также известная как поперечнополосатая мышца, это тип мышц,  прикрепленных к костям. Эти данные о количественном содержании скелетных  мышц.',
            },
            {
                title: 'Белок',
                msg: 'Белок - это сложные вещества, состоящие из аминокислот.  Являются неизменной составляющей частью рациона. Это главный строительный  материал, без которого невозможен рост мускулатуры и тканей в целом.',
            },
            {
                title: 'Неорганические соли',
                msg: 'Человеческое  тело состоит из органического, неорганического вещества и воды. Здесь  неорганическое вещество - это неорганические соли, которые составляют 5% от  массы тела.',
            },
            {
                title: 'Базальный метаболизм',
                msg: 'Базальный метаболизм - это  суточное потребление энергии организмом в состоянии бодрствования и покоя,  без учёта физической активности, приёма пищи, стресса или изменения внешней  температуры.',
            },
            {
                title: 'WHR (Соотношение талии к бедрам)',
                msg: 'Это соотношение объема талии к  объему бедер и является важным показателем для определения центрального  ожирения.',
            },
            {
                title: 'ИМТ (Индекс массы тела)',
                msg: 'ИМТ в  основном используется для оценки внешнего проявления ожирения, и это  общепринятый стандарт для измерения ожирения.',
            },
            {
                title: 'Процент жира в теле',
                msg: 'Процент  телесного жира - это измерение состава тела, которое показывает, какая часть  массы тела является жиром.',
            },
            {
                title: 'Уровень висцерального жира',
                msg: 'Висцеральный жир - это важный показатель для оценки скрытого  ожирения.',
            },
            {
                title: 'Внутриклеточная вода (ICW)',
                msg: 'Внутриклеточная жидкость: это жидкость, содержащаяся в клетках  организма, и является основным компонентом протоплазмы.',
            },
            {
                title: 'Внеклеточная вода (ECW)',
                msg: 'Внеклеточная жидкость: Обычно относится к внеклеточным жидкостям  организма, включая плазму и межклеточную жидкость между кровеносными сосудами  и тканевыми клетками.',
            },
            {
                title: 'Метаболический Возраст',
                msg: 'Метаболический возраст относится к работе тела и его здоровье связанных с метаболической функции.',
            },
        ],
        // 节段
        segment: {
            fat: 'Сегментный жир',
            muscle: 'Сегментная мышца',
            right: 'Право',
            left: 'Лево',
            standard: 'Нормально',
            lowStandard: 'Ниже',
            superStandard: 'Выше',
        },
    },
    // 体态
    shape: {
        title: 'Обзор оценки тела',
        titles: 'Осанка тела',
        status: 'Ваше текущее физическое состояние',
        suggest: 'Предложение',
        possibility: ', есть вероятность',
        models: {
            front: 'Спереди',
            left: 'Слева',
            right: 'Справа',
            top: 'Сзади',
            low: 'Ниже',
            high: 'Выше',
            normal: 'Нормально',
        },
        item: {
            deviate: 'Значения',
            result: 'Объяснение',
            normal: 'Нормально',
            abnormal: 'Ненормально',
            head: 'Отклонение головы вперед',
            headSlant: 'наклон головы',
            roundShoulderLeft: 'округлое плечо (левая сторона)',
            roundShoulderRight: 'округлое плечо (правая сторона)',
            highLowShoudler: 'перекос плеч',
            pelvis: 'Смещение таза вперед/Смещение таза назад',
            leftKneeCheck: 'оценка левого колена',
            rightKneeCheck: 'оценка правого колена',
            leg: 'форма ноги',
            leftLeg: 'Левая нога:',
            rightLeg: 'Правая нога:',
        },
        // 定义解读
        explain: [
            {
                title: 'Отклонение головы вперед',
                msg: 'Угол между точкой уха на левой стороне и линией, соединяющей  центр шеи и медианную линию сбоку',
            },
            {
                title: 'Наклон головы',
                msg: 'Угол между серединой передней части головы и центром шеи и  медианной линией спереди',
            },
            {
                title: 'Округлое плечо (левая сторона)',
                msg: 'Угол между линией, соединяющей самую высокую точку на левой  стороне спины, и касательной к плечу',
            },
            {
                title: 'Округлое плечо (правая сторона)',
                msg: 'Угол между линией, соединяющей самую высокую точку на правой  стороне спины, и касательной к плечу',
            },
            {
                title: 'Перекос плеч',
                msg: 'Вертикальное расстояние между точками левого и правого плеча на  спине',
            },
            {
                title: 'Смещение таза вперед/Смещение таза назад',
                msg: 'Угол между линией, соединяющей среднюю точку шеи, точку  тазобедренной кости и лодыжку на левой стороне ',
            },
            {
                title: 'Оценка левого колена',
                msg: 'Угол линии, соединяющей три точки левой боковой поверхности  бедра, коленного сустава и голеностопного сустава',
            },
            {
                title: 'Оценка правого колена',
                msg: 'Угол линии, соединяющей точки правой боковой поверхности  бедра, колена и лодыжки',
            },
            {
                title: 'Форма ноги',
                msg: 'Угол линии, соединяющей переднюю точку тазобедренной кости,  коленный сустав и точку лодыжки правой и левой ноги',
            },
        ],
        exception: [
            {
                title: 'Наклон влево',
                abnormal: '',
            },
            {
                title: 'Наклон вправо',
                abnormal: '',
            },
            {
                title: 'Левая сторона выше',
                abnormal: '',
            },
            {
                title: 'Правая сторона выше',
                abnormal: '',
            },
            {
                title: 'Наклон таза назад',
                abnormal: '',
            },
            {
                title: 'Наклон таза вперед',
                abnormal: '',
            },
            {
                title: 'Гиперэкстензия левого колена',
                abnormal: '',
            },
            {
                title: 'Деформация левого колена вперед',
                abnormal: '',
            },
            {
                title: 'Гиперэкстензия правого колена',
                abnormal: '',
            },
            {
                title: 'Деформация правого колена вперед',
                abnormal: '',
            },
            {
                title: 'Ноги в форме буквы K',
                abnormal: '',
            },
            {
                title: 'Ноги в форме буквы D',
                abnormal: '',
            },
            {
                title: 'Ноги в форме буквы X',
                abnormal: '',
            },
            {
                title: 'Ноги в форме буквы O',
                abnormal: '',
            },
        ],
    },
    // 围度信息
    girth: {
        title: 'Окружности',
        present: '(Присутствуют)',
        noHistory: 'Нет данных',
        tpl: [
            {
                title: 'Обхват шеи',
                key: 'neckGirth',
            },
            {
                title: 'Верхняя часть левой руки',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'Верхняя часть правой руки',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'Грудь',
                key: 'bustGirth',
            },
            {
                title: 'Верхняя талия',
                key: 'waistGirth',
            },
            {
                title: 'Средняя талия',
                key: 'midWaistGirth',
            },
            {
                title: 'Обхват  бедра',
                key: 'hipGirth',
            },
            {
                title: 'Обхват  левого бедра',
                key: 'leftThighGirth',
            },
            {
                title: 'Середина левого бедра',
                key: 'leftMidThighGirth',
            },
            {
                title: 'Минимальный  обхват левого бедра',
                key: 'leftMinThighGirth',
            },
            {
                title: 'Обхват правого бедра',
                key: 'rightThighGirth',
            },
            {
                title: 'Середина правого бедра',
                key: 'rightMidThighGirth',
            },
            {
                title: 'Минимальный  обхват правого бедра',
                key: 'rightMinThighGirth',
            },
            {
                title: 'Обхват левой икры',
                key: 'leftCalfGirth',
            },
            {
                title: 'Обхват правой икры',
                key: 'rightCalfGirth',
            },
            {
                title: 'Низкая талия',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'Функция  плеча',
        model: {
            val: 'Значения',
            scope: 'Maximale Aktivitätsposition',
            diff: 'Сеть',
        },
        normal: 'Оценено как нормальное.',
        normalAll: 'Нет значительных аномалий в  плечевых функциях.',
        conclusion: 'Заключение',
        analyse: 'Анализ:',
        suggest: 'Предложение:',
        cause: 'Точную причину должен установить  специалист',
    },
    reportTips: {
        title: 'Пожалуйста, свяжитесь с администратором, если вы хотите удалить  отчет.',
        btnMsg: 'Хорошо',
        alaryTips: 'Этот отчет был удален администратором. Перейдем к следующему  отчету!',
    },
    saveQr: {
        title: 'Отчет 3D сканера тела Visbody',
        btnMsg: 'Зажмите  изображение, чтобы сохранить его на вашем телефоне.',
        tips: 'Если вы столкнулись с тем, что не можете сохранить, то  перейдите в [Настройки] и разрешите доступ',
    },
    vanCalendar: {
        end: '结束',
        start: '开始',
        title: '日期选择',
        confirm: 'Подвердить',
        startEnd: '开始/结束',
        weekdays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthTitle: function monthTitle(year, month) {
            let monthStr = month
            if(month < 10) {
                monthStr  = `0${month}`
            }
            return monthStr + "." +  year;
        },
        rangePrompt: function rangePrompt(maxRange) {
            return "\u9009\u62E9\u5929\u6570\u4E0D\u80FD\u8D85\u8FC7 " + maxRange + " \u5929";
        }
    },
    share: {
        title: 'Пожалуйста, скопируйте ссылку ниже и поделитесь ею.',
        btnMsg: 'Копировать',
      },
    loading: "Загрузка",      // 第三方组件
    ...ruLocale,
    ...agrEn
}
