/*
 * @Description: Description
 * @Author: dpw
 * @Date: 2021-11-20 13:58:43
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-08-29 17:21:15
 */
import frLocale from 'element-ui/lib/locale/lang/fr'
import agrEn from './agr-en'
export default {
       // 登录页面
       login: {
        loginAndRegister: 'Inscription / Connexion',
        registerAndLogin: 'Connexion / Inscription',
        userLogin: 'Se  connecter',
        title: "Les comptes créés avec un email ou un numéro de téléphone sont indépendants l'un de l'autre, soyez attentif au type de compte choisi lors de la première connexion.",
        mobileLogin: 'Téléphone',
        emailLogin: 'E-mail',
        email: 'E-mail',
        loginBtn: 'Se  connecter',
        autoLogin: 'Se  souvenir de moi',
        retransmission: 's',
        formList: [
            {
                label: 'Entrez  votre numéro de téléphone',
                placeholder: 'Envoyer',
            },
            {
                label: 'Code',
                placeholder: 'Entrez  le code de vérification',
            },
            {
                label: 'Veuillez entrer votre e-mail',
            },
        ],
        btn: {
            submit: 'Créer un  compte',
            title: "Afin d'avoir des données précises, il est important d'entrer la bonne taille, le genre et l'âge.",
        },
        rule: {
            phone: {
                requiredMsg: 'Format  de numéro de téléphone invalide',
                patternMsg: 'Téléphone  non enregistré, créez votre compte',
            },
            authCode: {
                requiredMsg: 'Mauvais  code de vérification, veuillez le retaper',
            },
            email: {
                requiredMsg: "Mauvais format d'e-mail",
                patternMsg: 'E-mail non enregistré, créez votre compte',
            },
        },
        codeSuccess: 'Le code  de vérification a été envoyé, veuillez vérifier.',
        success: 'Envoi  réussi',
        error: "Échec de  l'envoi",
        loginSuccess: 'Connexion  réussie',
    },
    // 注册页面
    register: {
        mobileRegister: 'Téléphone',
        emailRegister: 'E-mail',
        enroll: 'Numéro  de téléphone portable déjà enregistré',
        enrollEmail: `L'e-mail est enregistré`,
        search: 'Rechercher',
        title: "Les comptes créés avec un email ou un numéro de téléphone sont indépendants l'un de l'autre, soyez attentif au type de compte choisi lors de la première connexion.",
        needRead: {
            agree: "Je suis  d'accord",
            privacy: '(Politique  de confidentialité)',
        },
        register: 'Se  connecter',
        check: `Vérifiez  " Politique de confidentialité de l'utilisateur "`,
        newUser: 'Se  connecter',
        form: {
            labelList: ['Nom', '* Genre', '* Taille', '* Âge'],
        },
        placeholder: ['Veuillez  entrer votre nom', 'Veuillez  entrer votre taille', 'Veuillez  entrer votre âge'],
        unit: ['Métrique', 'Impérial', 'Âge'],
        sexOptions: {
            male: 'Masculin',
            female: 'Féminin',
        },
        btn: 'Soumettre',
        rules: {
            metricheight: [`La plage de taille est de 2' 3" - 6' 7", veuillez la saisir à nouveau.`],
            heightMsg: [`La plage de taille est de 70 - 200cm, veuillez la saisir à nouveau.`],
            ageMsg: [`La plage  d'âge est de 10 - 99 ans, veuillez la saisir à nouveau.`],
            agePointMsg: [`Rempli  avec un nombre entier : l'âge ne peut être qu'un entier, veuillez renseigner  l'âge correct.`],
        },
        success: 'Inscription  réussie',
        userMessage: `Entrez  le nom d'utilisateur`,
        verify: 'Entrez  le code de vérification à 4 chiffres',
        errorMsg: 'Le  format de la hauteur ne supporte pas les décimales, veuillez le ressaisir',
        abnormalMsg: `Problème d'enregistrement`
    },
    // 模型合成
    model: {
        loading: 'Chargement... Veuillez patienter',
        modelLoading: 'Chargement  du modèle 3D',
        tabPane: [
            {
                title: 'Technologie  Deep Real Sense',
                msg: `Collecte  précise des détails locaux du corps grâce au scan 3D, assure une précision au  millimètre près des mesures de circonférence, et présente clairement les  changements de forme du corps après l'entraînement.`,
            },
            {
                title: 'Algorithme  de composition corporelle BDA',
                msg: "Visbody  utilise le système de mesure de composition corporelle le plus avancé -  l'algorithme BDA pour déterminer la composition corporelle. Cette méthode de  calcul basée sur le volume du corps évaluera plus précisément les risques  pour la santé liés à l'obésité.",
            },
            {
                title: 'Facteurs ayant causé des erreurs',
                msg: "Des  vêtements amples ou certaines décorations sur le corps causeront des erreurs,  veuillez porter des collants ou réduire la quantité de vêtements sur votre  corps si vous voulez obtenir les données les plus précises.",
            },
            {
                title: 'Obtenez  votre rapport',
                msg: "Il est recommandé d'ajouter l'URL de la page web à vos favoris pour faciliter la consultation du rapport à l'avenir. Vous pouvez également envoyer ce rapport à votre boîte mail pour le sauvegarder plus tard.",
            },
        ],
        collect: [ "Veuillez ajouter cette page à vos favoris pour pouvoir accéder à votre rapport plus tard.", ''],
        know: "J'ai  compris",
        reminder: [
            'Rappel  amical',
            "Votre avatar 3D a été généré, balayez vers la gauche et la droite pour le faire pivoter.",
            `Votre  rapport a été généré et peut être consulté en cliquant sur le bouton  "Voir le rapport".`,
            `Votre évaluation de posture a échoué. Veuillez consulter votre rapport de composition corporelle.`,
            `La mesure de votre composition corporelle a échoué. Veuillez consulter le rapport d’évaluation de la posture.`,
            `La mesure de vos circonférences corporelles a échoué. Veuillez retourner à l'appareil et tester à nouveau.`,
            `La mesure de votre composition corporelle a échoué. Veuillez retourner à l'appareil et tester à nouveau.`,
            `L'évaluation de votre posture a échoué. Veuillez retourner à l'appareil et tester à nouveau.`,
        ],
        btn: {
            viewReport: 'Voir le  rapport',
            bodyReport: 'Voir le  rapport de composition corporelle',
            postureReport: 'Voir le  rapport de posture corporelle',
            lastReport: 'Voir le  dernier rapport',
            loading: 'Chargement',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'Télécharger  le rapport',
            send: 'Envoyer  le rapport',
            report: 'Rapport',
        },
        reportSuccess: 'Réussi!',
        tabPane: {
            labelList: ['Temps de  mesure', 'Article', 'Composition  corporelle', 'Évaluation  de la posture', "Fonction  de l'épaule"],
        },
        sendEmail: 'E-mail :',
        requiredMsg: 'Entrez votre adresse e-mail',
        patternMsg: 'Veuillez  entrer une adresse email valide',
        send: 'Envoyer',
        download: 'Télécharger  le rapport',
        sendReport: 'Envoyer  le rapport',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'Changement  de genre réussi',
            height: 'Changement  de taille réussi',
            age: `Changement  d'âge réussi`,
        },
        logOut: 'Se  déconnecter',
        personal: 'Centre  personnel',
        cut: `Changer  d'unités/langues`,
        changeSort: 'Changement  de rapport de produit',
        describe: `Après avoir changé le modèle de l'appareil, seulement les rapports générés à l'aide cet appareil seront affichés.`,
        describeBtn: 'Après  avoir changé le modèle de produit, vous serez redirigé vers le dernier  rapport de mesure sous ce modèle.',
        descReport: 'Le  changement du rapport de produit a réussi',
        unit: ['Métrique  (kg, cm)', 'Impérial  (ft, in, lb)'],
        read: {
            reading: 'Lire',
            privacy: '(Politique  de confidentialité)',
        },
        setting: 'Paramètres',
        form: {
            labelList: ['Téléphone', 'Unités', 'Langues', 'Nom', 'Genre', 'Taille', 'Âge'],
        },
        placeholder: [`Changez  votre nom d'utilisateur`, 'Nouveau  genre', 'Taille  mise à jour', 'Âge mis  à jour'],
        btn: {
            cancelBtn: 'Annuler',
            accomplishBtn: 'Terminer',
            confirmBtn: 'Confirmer',
        },
        dialog: [
            `Le genre est l'une des bases importantes pour la mesure de la composition corporelle. Les données calculées après modification seront différentes des données précédentes. Voulez-vous confirmer le changement ?`,
            `La taille est l'une des bases importantes pour la mesure de la composition corporelle. Les données calculées après modification seront différentes des données précédentes. Veuillez confirmer la modification.`,
            `L'âge est l'une des bases importantes pour la mesure de la composition corporelle. Les données calculées après modification seront différentes des données précédentes. Veuillez confirmer la modification.`,
        ],
        rules: {
            metricheight: [`Veuillez inscrire une taille entre 2' 3" to 6' 7"`],
            heightMsg: [`Veuillez inscrire une taille entre 70cm et 200cm`],
            ageMsg: [`Veuillez  inscrire l'âge entre 10 et 99 ans.`, `Un nombre non entier est saisi : l'âge ne peut être qu'un nombre entier, veuillez entrer l'âge juste`],
        },
        heightMsg: 'Veuillez choisir la taille à nouveau',
        ageMsg: `Veuillez choisir l'âge à nouveau`,
        ModelBinding: `Il semble que quelqu'un ait déjà scanné le code QR, veuillez refaire vos tests!`,
        ReportEmpty: {
            title: `Vous  n'avez encore aucun rapport`,
            desc: 'Venez au  scanner corporel 3D de Visbody et testez à nouveau !',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: `Il semble que quelqu'un ait déjà scanné le code QR, veuillez refaire vos tests!`,
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: `Vous  n'avez encore aucun rapport`,
        desc: 'Venez au  scanner corporel 3D de Visbody et testez à nouveau !',
        descS30: 'Venez au  scanner corporel 3D de Visbody-S30 et testez à nouveau !',
        descM30: 'Venez au  scanner corporel 3D de Visbody-M30 et testez à nouveau !',
    },
    // 用户信息
    userInfo: {
        height: 'Taille :',
        age: 'Âge :',
        unit: '',
        weight: 'poids'
    },
    // 体成分
    mass: {
        title: `Aperçu  de l'évaluation de la composition corporelle`,
        titles: 'Composition  corporelle',
        springFrame: '',
        massFrame: 'Pas de  données de mesure pour la journée',
        contrast: 'Choisir un rapport à comparer',
        contrastReport: 'Choisir un rapport à comparer',
        noRecord: `Pas  d'enregistrements`,
        score: 'Score',
        status: 'Votre composition corporelle actuelle est',
        WT: 'Poids',
        PBF: 'TGC',
        endSymbol: '。',
        // 测量相关
        measure: {
            value: 'Valeur',
            standardRange: 'Plage  standard',
            comparedWithLastLime: 'Net',
            comparedNet: 'Comparé  au dernier score',
            current: '本次测量分数',
            WT: 'Poids',
            FFM: 'MMC',
            BFM: 'MGC',
            LM: 'Masse musculaire',
            TBW: 'Eau corporelle totale',
            SM: 'MMS',
            PROTEIN: 'Masse de protéine',
            TM: 'Sel inorganique',
            BMR: 'MB',
            WHR: 'RTH',
            BMI: 'IMC',
            PBF: 'TGC',
            VFG: 'Niveau de graisse viscérale',
            unit: 'kg',
            BMRUnit: 'kcal/d',
        },
        // 定义解读
        explain: [
            {
                title: 'Poids',
                msg: `Le poids est la somme de l'eau corporelle, des protéines, des sels inorganiques et graisse corporelle.`,
            },
            {
                title: 'MMC',
                msg: 'La masse maigre corporelle (MMC) est le poids total du corps sans graisse.',
            },
            {
                title: 'MGC',
                msg: 'La  masse grasse corporelle (MGC) est la somme de la graisse sous-cutanée, de la  graisse viscérale et de la graisse musculaire.',
            },
            {
                title: 'Masse musculaire',
                msg: 'La masse musculaire est le groupe de tissus maigres du corps, comprenant les muscles squelettiques, les muscles lisses et le muscle cardiaque.',
            },
            {
                title: 'Eau corporelle totale',
                msg: `L'eau corporelle représente la plus grande proportion de la composition du corps humain, représentant 50 à 70 % du poids corporel. L'eau corporelle est distribuée dans les cellules humaines et les fluides corporels, dont la plupart sont présents dans les cellules musculaires.`,
            },
            {
                title: 'MMS',
                msg: 'La Masse Musculaire Squelettique (MMS), également connue sous le nom de muscle strié, est un type de muscle attaché aux os. Cette donnée représente la quantité de muscle squelettique dans le corps.',
            },
            {
                title: 'Masse de protéine',
                msg: `La protéine est une substance solide contenant de l'azote, présente dans toutes les cellules du corps humain et participant à la composition cellulaire. C'est la composante principale de la masse musculaire.`,
            },
            {
                title: 'Sel inorganique',
                msg: `Le corps humain est composé de matières organiques, de matières inorganiques et d'eau. La matière inorganique ici est les sels inorganiques qui représentent 5% du poids corporel.`,
            },
            {
                title: 'MB',
                msg: `Le Métabolisme de Base (MB) est la quantité d'énergie totale quotidienne dont votre corps a besoin, au repos, pour remplir ses fonctions vitales les plus élémentaires comme la respiration, la circulation, le traitement des nutriments et la production cellulaire. Il n’est pas affecté par l’exercice, la nourriture, le stress, les changements de température externe, etc.`,
            },
            {
                title: 'RTH',
                msg: `Le ratio taille-hanches (RTH), rapport entre la circonférence de la taille et celle des hanches, est un indicateur important pour déterminer l'obésité centrale.`,
            },
            {
                title: 'IMC',
                msg: `L'Indice de Masse Corporelle (IMC) est un calcul basé sur votre taille et votre poids, principalement utilisé pour évaluer le niveau d'obésité. Il s'agit d'une norme internationalement reconnue pour mesurer la graisse corporelle et les risques pour la santé.`,
            },
            {
                title: 'TGC',
                msg: `Le Taux de Graisse Corporelle (TGC) est une mesure de la composition corporelle indiquant combien du poids corporel est de la graisse.`,
            },
            {
                title: 'Niveau de graisse viscérale',
                msg: `Le niveau de graisse viscérale est un indicateur important pour évaluer l'obésité cachée qui se trouve sous la surface abdominal entourant les organes vitaux et  est lié à des problèmes de santé significatifs.`,
            },
            {
                title: 'Eau intracellulaire',
                msg: `L'eau intracellulaire est la quantité d'eau contenue dans les cellules du corps et est essentielle aux fonctions métaboliques des cellules. Elle représente les 2/3 de l’eau corporelle totale.`,
            },
            {
                title: 'Eau Extracellulaire',
                msg: `L'eau extracellulaire est la quantité d'eau contenue dans les fluides corporels extracellulaires, y compris le plasma sanguin et le liquide interstitiel entre les vaisseaux sanguins et les cellules tissulaires. Il facilite le mouvement des électrolytes, de l’oxygène et des déchets issus des processus métaboliques. Il représente généralement 1/3 de l’eau corporelle totale.`,
            },
            {
                title: 'Âge Métabolique',
                msg: 'L\'âge métabolique fait référence à la performance du corps et de ses fonctions de santé liées au métabolisme.',
            },
        ],
        // 节段
        segment: {
            fat: 'Masse graisseuse par segment',
            muscle: 'Masse musculaire par segment',
            right: 'Droite',
            left: 'Gauche',
            standard: 'Normal',
            lowStandard: 'Bas',
            superStandard: 'Élevé',
        },
    },
    // 体态
    shape: {
        title: `Aperçu de l'évaluation posturale`,
        titles: 'Posture',
        status: 'Votre posture corporelle actuelle est',
        suggest: 'Suggestion',
        possibility: ', il y a',
        models: {
            front: 'Face',
            left: 'Côté  gauche',
            right: 'Côté  droit',
            top: 'Dos',
            low: 'Bas',
            high: 'Élevé',
            normal: 'Normal',
        },
        item: {
            deviate: 'Valeur',
            result: 'Explication',
            normal: 'Normale',
            abnormal: 'Anormale',
            head: `posture de la tête vers avant`,
            headSlant: 'Inclinaison de la tête',
            roundShoulderLeft: 'Posture  des épaules arrondies (côté gauche)',
            roundShoulderRight: 'Posture des épaules arrondies (côté droit)',
            highLowShoudler: 'Épaules inégales',
            pelvis: `déplacement avant / arrière du bassin`,
            leftKneeCheck: 'Évaluation  du genou gauche',
            rightKneeCheck: 'Évaluation du genou droit',
            leg: 'forme des jambes',
            leftLeg: 'Jambe gauche :',
            rightLeg: 'Jambe droite :',
        },
        // 定义解读
        explain: [
            {
                title: `Posture de la tête vers avant`,
                msg: `L'angle  entre le point de l'oreille sur le côté gauche et la ligne reliant le centre  du cou et la ligne médiane sur le côté`,
            },
            {
                title: 'Inclinaison de la tête',
                msg: `L'angle  entre le point médian de la tête avant et le centre du cou et la ligne  médiane de l'avant`,
            },
            {
                title: 'Posture des épaules arrondies (côté gauche)',
                msg: `L'angle  entre la ligne reliant le point le plus élevé du côté gauche du dos et la  ligne tangente à l'épaule`,
            },
            {
                title: 'Posture des épaules arrondies (côté droit)',
                msg: `L'angle  entre la ligne reliant le point le plus élevé du côté droit du dos et la  tangente à l'épaule`,
            },
            {
                title: 'Épaules inégales',
                msg: `La distance verticale entre les marqueurs de l'épaule gauche et droite sur la vue de dos`,
            },
            {
                title: `Déplacement avant / arrière du bassin`,
                msg: `L'angle  inclus de la ligne reliant le point central du cou, le point de l'os de la  hanche et le point de la cheville sur le côté gauche`,
            },
            {
                title: `Évaluation du genou gauche`,
                msg: `L'angle  inclus de la ligne à trois points reliant le point de l'os de la hanche,  l'articulation du genou et le point de la cheville sur le côté gauche`,
            },
            {
                title: 'Évaluation du genou droit',
                msg: `L'angle  inclus de la ligne à trois points reliant le point de l'os de la hanche,  l'articulation du genou et le point de la cheville sur le côté droit`,
            },
            {
                title: 'Forme des jambes',
                msg: `L'angle  inclus de la ligne reliant le point de l'os de la hanche, l'articulation du  genou et le point de la cheville sur le devant des jambes gauche et droite`,
            },
        ],
        exception: [
            {
                title: 'Incliné vers la gauche',
                abnormal: 'incliné vers la gauche',
            },
            {
                title: 'Incliné vers la droite',
                abnormal: 'Incliné vers la droite',
            },
            {
                title: 'Épaule gauche élevée',
                abnormal: 'Épaule gauche élevée',
            },
            {
                title: 'Épaule droite élevée',
                abnormal: 'Épaule droite élevée',
            },
            {
                title: 'Déplacement arrière du bassin',
                abnormal: 'Déplacement arrière du bassin',
            },
            {
                title: 'Déplacement avant du bassin',
                abnormal: 'Déplacement avant du bassin',
            },
            {
                title: 'Hyperextension du genou gauche',
                abnormal: 'Hyperextension du genou gauche',
            },
            {
                title: 'Flexion excessive du genou gauche',
                abnormal: 'flexion excessive du genou gauche',
            },
            {
                title: 'Hyperextension du genou droit',
                abnormal: 'hyperextension du genou droit',
            },
            {
                title: 'Flexion excessive du genou droit',
                abnormal: 'flexion excessive du genou droit',
            },
            {
                title: 'Jambes en forme de K',
                abnormal: 'jambes en forme de K',
            },
            {
                title: 'Jambes en forme de D',
                abnormal: 'jambes en forme de D',
            },
            {
                title: 'Jambes en forme de X',
                abnormal: 'jambes en forme de X',
            },
            {
                title: 'Jambes en forme de O',
                abnormal: 'jambes en forme de O',
            },
        ],
    },
    // 围度信息
    girth: {
        title: 'Circonférences',
        present: '(Présent)',
        noHistory: 'Pas de données',
        tpl: [
            {
                title: 'Cou',
                key: 'neckGirth',
            },
            {
                title: 'Haut du bras gauche',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'Haut du bras droit',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'Poitrine',
                key: 'bustGirth',
            },
            {
                title: 'Taille  haute',
                key: 'waistGirth',
            },
            {
                title: 'Taille  milieu',
                key: 'midWaistGirth',
            },
            {
                title: 'Hanches',
                key: 'hipGirth',
            },
            {
                title: 'Cuisse  gauche',
                key: 'leftThighGirth',
            },
            {
                title: 'Milieu  de la cuisse gauche',
                key: 'leftMidThighGirth',
            },
            {
                title: 'Bas de la cuisse gauche',
                key: 'leftMinThighGirth',
            },
            {
                title: 'Cuisse droite',
                key: 'rightThighGirth',
            },
            {
                title: 'Milieu  de la cuisse droite',
                key: 'rightMidThighGirth',
            },
            {
                title: 'Bas de la cuisse droite',
                key: 'rightMinThighGirth',
            },
            {
                title: 'Mollet gauche',
                key: 'leftCalfGirth',
            },
            {
                title: 'Mollet droit',
                key: 'rightCalfGirth',
            },
            {
                title: 'Taille  basse',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: `Fonction  de l'épaule`,
        model: {
            val: 'Valeur',
            scope: 'Emplacement d\'Activité Maximum',
            diff: 'Net',
        },
        normal: 'Aucune  anomalie notable détectée.',
        normalAll: `L'évaluation  fonctionnelle de vos épaules est très bonne.`,
        conclusion: 'Conclusion',
        analyse: 'Observation :',
        suggest: 'Suggestion: ',
        cause: 'Veuillez consulter un professionnel de la santé pour plus amples renseignements.',
    },
    reportTips: {
        title: `Veuillez  contacter l'administrateur si vous souhaitez supprimer le rapport.`,
        btnMsg: 'OK',
        alaryTips: `Ce  rapport a été supprimé par l'administrateur. On passera au rapport suivant!`,
    },
    saveQr: {
        title: 'Rapport  du scanner corporel 3D Visbody',
        btnMsg: `Appuyez  longuement sur l'image pour la sauvegarder sur votre téléphone.`,
        tips: 'Si  vous constatez que vous ne pouvez pas sauvegarder, veuillez aller dans  [Paramètres] et ouvrir les permissions correspondantes.',
    },
    share: {
        title: 'Veuillez copier le lien ci-dessous et le partager.',
        btnMsg: 'Copier',
    },
    ...frLocale,
    ...agrEn
}
