import ptLocale from 'element-ui/lib/locale/lang/pt'
import agrZh from './agr-en'
export default {
    // 登录页面
    login: {
        loginAndRegister: 'Inscrever-se / Entrar',
        registerAndLogin: 'Entrar / Inscrever-se',
        userLogin: 'Entrar',
        title: 'Contas de celular e e-mail são independentes.',
        mobileLogin: 'Telefone',
        emailLogin: 'E-mail',
        email: 'E-mail',
        loginBtn: 'Entrar',
        autoLogin: 'Lembrar-me',
        retransmission: 's',
        formList: [
            {
                label: 'Insira o seu número de telefone',
                placeholder: 'Enviar',
            },
            {
                label: 'Código verif',
                placeholder: 'Inserir código de verificação',
            },
            {
                label: 'Por favor, insira o seu e-mail',
            },
        ],
        btn: {
            submit: 'Criar Conta',
            title: 'Para obter dados precisos, é importante inserir corretamente altura, gênero e idade.',
        },
        rule: {
            phone: {
                requiredMsg: 'Formato de Número de Telefone Inválido',
                patternMsg: 'Telefone não registrado, crie sua conta',
            },
            authCode: {
                requiredMsg: 'Código de Verificação Errado, Por Favor, Digite Novamente',
            },
            email: {
                requiredMsg: 'Formato de E-mail Errado',
                patternMsg: 'E-mail Não Registrado, Crie Sua Conta',
            },
        },
        codeSuccess: 'O código de verificação foi enviado, por favor, verifique.',
        success: 'Envio bem-sucedido',
        error: 'Falha no Envio',
        loginSuccess: 'Login bem-sucedido',
    },
    // 注册页面
    register: {
        mobileRegister: 'Telefone',
        emailRegister: 'E-mail',
        enroll: 'Número de Celular Já Registrado',
        enrollEmail: 'O email está registrado',
        search: 'Pesquisar',
        title: 'A conta configurada por e-mail e número de telefone são independentes uma da outra, por favor',
        needRead: {
            agree: 'Eu concordo',
            privacy: '(Política de Privacidade)',
        },
        register: 'Entrar',
        check: 'Verifique "Privacidade do Usuário"',
        newUser: 'Entrar',
        form: {
            labelList: ['Nome', '* Gênero', '* Altura', '* Idade'],
        },
        placeholder: ['Por favor, insira o seu nome', 'Por favor, insira a sua altura', 'Por favor, insira a sua idade'],
        unit: ['Métrico', 'Imperial', ''],
        sexOptions: {
            male: 'Masculino',
            female: 'Feminino',
        },
        btn: 'Enviar',
        rules: {
            metricheight: [`A faixa de altura é de 2'3" - 6'7", por favor, insira novamente.`],
            heightMsg: ['A faixa de altura é de 70 - 200cm, por favor, insira novamente.'],
            ageMsg: ['A faixa de idade é de 10 - 99, por favor, insira novamente.'],
            agePointMsg: ['Preenchido não-inteiro: a idade só pode ser um número inteiro, por favor, preencha a idade correta'],
        },
        success: 'Registro Bem-sucedido',
        userMessage: 'Insira o Nome de Usuário',
        verify: 'Insira o Código de Verificação de 4 Dígitos',
        errorMsg: 'o formato de altura não suporta decimais, por favor, insira novamente',
        abnormalMsg: 'Anomalia de registro'
    },
    // 模型合成
    model: {
        loading: 'Carregando...Por favor, aguarde um momento',
        modelLoading: 'Carregando Modelo 3D',
        tabPane: [
            {
                title: 'Tecnologia Deep Real Sense',
                msg: 'Coleta com precisão os detalhes locais do corpo através da digitalização 3D, garante a precisão em milímetros da medição da circunferência, e apresenta claramente as mudanças na forma do corpo após o treino.',
            },
            {
                title: 'Algoritmo de Composição Corporal BDA',
                msg: 'Visbody usa o sistema de medição de composição corporal mais avançado - algoritmo BDA para determinar a composição corporal. Este método de cálculo baseado no volume do corpo avaliará mais precisamente os riscos de saúde trazidos pela obesidade.',
            },
            {
                title: 'Fatores que causaram erros',
                msg: 'Roupas soltas ou algumas decorações no/corpo causarão erros, por favor, use roupas justas ou reduza a quantidade de roupas no seu corpo se quiser obter os dados mais precisos.',
            },
            {
                title: 'Obtenha o seu relatório',
                msg: 'É recomendado marcar a URL da página da web para facilitar a visualização do relatório no futuro. Você também pode enviar este relatório para o seu e-mail para salvá-lo posteriormente.',
            },
        ],
        collect: ['Por favor, marque esta página, fácil de revisar o relatório no futuro.', ''],
        know: 'Eu Entendi',
        reminder: [
            'Recordação gentil',
            'Seu modelo de avatar 3D foi gerado, deslize para a esquerda e para a direita para girar o modelo.',
            'Seu relatório foi gerado e pode ser visualizado clicando no botão "Ver Relatório".',
            'Sua avaliação corporal não foi bem-sucedida, por favor, reveja o relatório de composição corporal.',
            'Sua composição corporal não foi bem-sucedida, por favor, reveja o relatório de avaliação postural.',
            'Sua medição não foi bem-sucedida, por favor, vá ao dispositivo e teste novamente.',
            'A medição da composição corporal não foi bem-sucedida, por favor, vá ao dispositivo e teste novamente.',
            'A avaliação postural não foi bem-sucedida, por favor, vá ao dispositivo e teste novamente.',
        ],
        btn: {
            viewReport: 'Ver Relatório',
            bodyReport: 'Ver Relatório de Composição Corporal',
            postureReport: 'Ver Relatório de Postura Corporal',
            lastReport: 'Ver Último Relatório',
            loading: 'Carregando',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'Baixar o Relatório',
            send: 'Enviar Relatório',
            report: 'Relatório',
        },
        reportSuccess: 'Sucesso!',
        tabPane: {
            labelList: ['Hora da Medição', 'Item', 'Composição Corporal', 'Avaliação da Postura', 'Função do Ombro'],
        },
        sendEmail: 'E-mail:',
        requiredMsg: 'Insira o seu endereço de e-mail',
        patternMsg: 'Por favor, insira um endereço de e-mail válido',
        send: 'Enviar',
        download: 'Baixar O Relatório',
        sendReport: 'Enviar Relatório',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'Gênero alterado com sucesso',
            height: 'Altura alterada com sucesso',
            age: 'Idade alterada com sucesso',
        },
        logOut: 'Sair',
        personal: 'Centro Pessoal',
        cut: 'Alterar Unidades/Idiomas',
        changeSort: 'Troca de Relatório de Produto',
        describe: '* Após trocar o modelo do produto, todos os relatórios de medição sob este modelo serão exibidos.',
        describeBtn: 'Após trocar o modelo do produto, você será redirecionado para o relatório de medição mais recente desse modelo.',
        descReport: 'Relatório de produto trocado com sucesso',
        unit: ['Métrico (kg, cm)', 'Imperial (ft, in, lb)'],
        read: {
            reading: 'Leia',
            privacy: '(Política de Privacidade)',
        },
        setting: 'Configurações',
        form: {
            labelList: ['Telefone', 'Unidades', 'Idiomas', 'Nome', 'Gênero', 'Altura', 'Idade'],
        },
        placeholder: ['Alterar Seu Nome de Usuário', 'Novo Gênero', 'Altura Atualizada', 'Idade Atualizada'],
        btn: {
            cancelBtn: 'Cancelar',
            accomplishBtn: 'Completar',
            confirmBtn: 'Confirmar',
        },
        dialog: [
            'O gênero é uma das bases importantes para a medição da composição corporal. Os dados medidos após a modificação serão diferentes dos dados anteriores. Por favor, confirme se você quer mudar?',
            'A altura é uma das bases importantes para a medição da composição corporal. Os dados medidos após a modificação serão diferentes dos dados anteriores. Por favor, confirme se você quer mudar?',
            'A idade é uma das bases importantes para a medição da composição corporal. Os dados medidos após a modificação serão diferentes dos dados anteriores. Por favor, confirme se você quer mudar?',
        ],
        rules: {
            metricheight: [`Por favor, preencha a faixa de altura correta (2'3" a 6'7")`],
            heightMsg: ['Por favor, preencha a altura dentro de 70cm - 200cm'],
            ageMsg: ['Por favor, preencha a idade dentro de 10 - 99 anos.'],
        },
        heightMsg: 'Por Favor, Reescolha a Altura',
        ageMsg: 'Por Favor, Reescolha a Idade',
        ModelBinding: 'Parece que alguém já escaneou o código, por favor, meça novamente!',
        ReportEmpty: {
            title: 'Você ainda não tem nenhum relatório',
            desc: 'Venha para o scanner corporal 3D Visbody e teste novamente!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'Parece que alguém já escaneou o código, por favor, meça novamente!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: 'Você ainda não tem nenhum relatório',
        desc: 'Venha para o scanner corporal 3D Visbody e teste novamente!',
        descS30: 'Venha para o scanner corporal 3D Visbody-S30 e teste novamente!',
        descM30: 'Venha para o scanner corporal 3D Visbody-M30 e teste novamente!',
    },
    // 用户信息
    userInfo: {
        height: 'Altura:',
        age: 'Idade:',
        unit: '',
        weight: 'peso'
    },
    // 体成分
    mass: {
        title: 'Visão Geral da Avaliação da Composição Corporal',
        titles: 'Composição Corporal',
        springFrame: '',
        massFrame: 'Sem dados de medição para o dia',
        contrast: 'Escolha um relatório para comparar',
        contrastReport: 'Escolha um relatório para comparar',
        noRecord: 'Sem registros',
        score: 'Pontuação',
        status: 'Seu estado atual de composição corporal ',
        WT: 'peso ',
        PBF: 'percentagem de gordura corporal ',
        endSymbol: '。',
        // 测量相关
        measure: {
            value: 'Valores',
            standardRange: 'Faixa padrão',
            comparedWithLastLime: 'Rede',
            comparedNet: 'Comparado com a última pontuação',
            current: '本次测量分数',
            WT: 'Peso',
            FFM: 'Massa Livre de Gordura',
            BFM: 'Massa Gordura',
            LM: 'Massa Muscular',
            TBW: 'Água Total',
            SM: 'MME',
            PROTEIN: 'Proteína',
            TM: 'Minerais',
            BMR: 'Basal Metabolic Rate',
            WHR: 'Relação Cintura-Quadril',
            BMI: 'IMC',
            PBF: 'BFP (Percentagem de Gordura Corporal)',
            VFG: 'Nível de Gordura Visceral',
            unit: 'kg',
            BMRUnit: 'kcal/d',
        },
        // 定义解读
        explain: [
            {
                title: 'Peso ',
                msg: 'O peso é a soma da água corporal, proteína, sal inorgânico e peso corporal.',
            },
            {
                title: 'Massa Livre de Gordura',
                msg: 'Massa corporal magra é o peso corporal total sem gordura.',
            },
            {
                title: 'Massa Gordura',
                msg: 'A massa de gordura corporal é a soma da gordura subcutânea, gordura visceral e gordura muscular.',
            },
            {
                title: 'Massa Muscular',
                msg: 'A massa magra suave é a massa corporal magra, que inclui músculo esquelético, músculo liso e músculo cardíaco.',
            },
            {
                title: 'Água Total',
                msg: 'A maior parte do corpo humano é água com uma quantidade de 50%-70% do peso corporal. E a água corporal está principalmente nas células humanas e nos fluidos corporais, a maioria dos quais está nas células musculares.',
            },
            {
                title: 'MME',
                msg: 'A massa muscular esquelética, também conhecida como músculo estriado, é um tipo de músculo ligado aos ossos. Estes dados contêm a quantidade de Músculo Esquelético.',
            },
            {
                title: 'Proteína',
                msg: 'A proteína é uma substância sólida composta por aminoácidos, presente em todas as células do corpo humano.',
            },
            {
                title: 'Minerais',
                msg: 'O corpo humano é composto por matéria orgânica, matéria inorgânica e água. A matéria inorgânica aqui são sais inorgânicos que representam 5% do peso corporal.',
            },
            {
                title: 'Basal Metabolic Rate',
                msg: 'A taxa de metabolismo basal é a energia total consumida em um dia quando o corpo está em repouso, não afetado por exercícios, objetos físicos, nervosismo, mudanças de temperatura externa, etc.',
            },
            {
                title: 'Relação Cintura-Quadril',
                msg: 'A relação da circunferência da cintura com o quadril, é um indicador importante para determinar a obesidade central.',
            },
            {
                title: 'IMC',
                msg: 'O IMC é principalmente usado para avaliar a aparência da obesidade, e é um padrão comum para medir a gordura corporal.',
            },
            {
                title: 'PGC',
                msg: 'PGC é uma medida da composição corporal que indica quanto do peso corporal é gordura.',
            },
            {
                title: 'Água Intracelular',
                msg: 'É o fluido corporal contido nas células do corpo e é o componente básico do protoplasma.',
            },
            {
                title: 'Água extra-celular',
                msg: 'Normalmente se refere a fluidos corporais extracelulares, incluindo plasma e fluido intersticial entre vasos sanguíneos e células de tecido.',
            },
            {
                title: 'Nível de Gordura Visceral',
                msg: 'A gordura visceral é um indicador importante para avaliar a obesidade oculta.',
            },
            {
                title: 'Idade Metabólica',
                msg: 'Idade metabólica é o desempenho e funções de saúde do metabolismo do corpo.',
            },
        ],
        // 节段
        segment: {
            fat: 'Gordura segmental',
            muscle: 'Músculo segmental',
            right: 'Direito',
            left: 'Esquerda',
            standard: 'Normal',
            lowStandard: 'Abaixo',
            superStandard: 'Acima',
        },
    },
    // 体态
    shape: {
        title: 'Visão Geral da Avaliação Corporal',
        titles: 'Postura',
        status: 'Sua condição física atual',
        suggest: 'Sugestão',
        possibility: ', existe a possibilidade de ',
        models: {
            front: 'Frente',
            left: 'Lado esquerdo',
            right: 'Lado direito',
            top: 'Atrás',
            low: 'Abaixo',
            high: 'Acima',
            normal: 'Normal',
        },
        item: {
            deviate: 'Valores',
            result: 'Explicação',
            normal: 'Normal',
            abnormal: 'Anormal',
            head: 'Postura da cabeça para a frente',
            headSlant: 'Inclinação da cabeça',
            roundShoulderLeft: 'Postura de ombros arredondados (lado esquerdo)',
            roundShoulderRight: 'Postura de ombros arredondados (lado direito)',
            highLowShoudler: 'ombros desiguais',
            pelvis: 'Movimento anterior/posterior da pelve',
            leftKneeCheck: 'avaliação do joelho esquerdo',
            rightKneeCheck: 'avaliação do joelho direito',
            leg: 'Tipo de perna',
            leftLeg: 'Perna esquerda:',
            rightLeg: 'Perna direita:',
        },
        // 定义解读
        explain: [
            {
                title: 'Postura da Cabeça para Frente',
                msg: 'O ângulo entre o ponto da orelha no lado esquerdo e a linha mediana do mesmo lado (esquerdo)',
            },
            {
                title: 'Inclinação da Cabeça',
                msg: 'O ângulo entre o ponto médio da frente da cabeça, o centro do pescoço e a linha mediana frontal.',
            },
            {
                title: 'Postura dos Ombros Arredondados (lado esquerdo)',
                msg: 'O ângulo entre a linha conectando o ponto mais alto no lado esquerdo das costas e a linha tangente ao ombro',
            },
            {
                title: 'Postura dos Ombros Arredondados (lado direito)',
                msg: 'O ângulo entre a linha conectando o ponto mais alto no lado direito das costas e a tangente ao ombro',
            },
            {
                title: 'Ombros Desiguais',
                msg: 'A distância vertical entre os pontos do ombro esquerdo e direito nas costas',
            },
            {
                title: 'Deslocamento anterior/posterior da pélvis',
                msg: 'O ângulo incluído da linha conectando o ponto central do pescoço, o ponto do osso do quadril e o ponto do tornozelo no lado esquerdo',
            },
            {
                title: 'Avaliação do Joelho Esquerdo',
                msg: 'O ângulo incluído da linha de três pontos conectando o ponto do osso do quadril, a articulação do joelho e o ponto do tornozelo no lado esquerdo',
            },
            {
                title: 'Avaliação do Joelho Direito',
                msg: 'O ângulo incluído da linha de três pontos conectando o ponto do osso do quadril, a articulação do joelho e o ponto do tornozelo no lado direito',
            },
            {
                title: 'Formato da Perna',
                msg: 'O ângulo incluído da linha conectando o ponto do osso do quadril, a articulação do joelho e o ponto do tornozelo na frente das pernas esquerda e direita',
            },
        ],
        exception: [
            {
                title: 'Inclinado para o Lado Esquerdo',
                abnormal: '',
            },
            {
                title: 'Inclinado para o Lado Direito',
                abnormal: '',
            },
            {
                title: 'Esquerda Alta',
                abnormal: '',
            },
            {
                title: 'Direita Alta',
                abnormal: '',
            },
            {
                title: 'Inclinação Posterior da Pélvis',
                abnormal: '',
            },
            {
                title: 'Inclinação Anterior da Pélvis',
                abnormal: '',
            },
            {
                title: 'Hiperextensão do Joelho Esquerdo',
                abnormal: '',
            },
            {
                title: 'Flexão Anterior do Joelho Esquerdo',
                abnormal: '',
            },
            {
                title: 'Hiperextensão do Joelho Direito',
                abnormal: '',
            },
            {
                title: 'Flexão Anterior do Joelho Direito',
                abnormal: '',
            },
            {
                title: 'Pernas em Forma de K',
                abnormal: '',
            },
            {
                title: 'Pernas em Forma de D',
                abnormal: '',
            },
            {
                title: 'Pernas em Forma de X',
                abnormal: '',
            },
            {
                title: 'Pernas em Forma de O',
                abnormal: '',
            },
        ],
    },
    // 围度信息
    girth: {
        title: 'Circunferências',
        present: '(Presente)',
        noHistory: 'Sem dados',
        tpl: [
            {
                title: 'Circunferência do pescoço',
                key: 'neckGirth',
            },
            {
                title: 'Braço esquerdo',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'Braço superior direito',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'Busto',
                key: 'bustGirth',
            },
            {
                title: 'Cintura Alta',
                key: 'waistGirth',
            },
            {
                title: 'Cintura média',
                key: 'midWaistGirth',
            },
            {
                title: 'Linha Hipline',
                key: 'hipGirth',
            },
            {
                title: 'Coxa esquerda',
                key: 'leftThighGirth',
            },
            {
                title: 'Coxa esquerda do meio',
                key: 'leftMidThighGirth',
            },
            {
                title: 'Circunferência mínima da coxa esquerda',
                key: 'leftMinThighGirth',
            },
            {
                title: 'Circunferência direita da coxa',
                key: 'rightThighGirth',
            },
            {
                title: 'Coxa direita do meio',
                key: 'rightMidThighGirth',
            },
            {
                title: 'Circunferência mínima da coxa direita',
                key: 'rightMinThighGirth',
            },
            {
                title: 'Circunferência esquerda da barriga da perna',
                key: 'leftCalfGirth',
            },
            {
                title: 'Circunferência direita da barriga da perna',
                key: 'rightCalfGirth',
            },
            {
                title: 'Cintura baixa',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'Função do Ombro',
        model: {
            val: 'Valores',
            scope: 'Local de Atividade Máxima',
            diff: 'Rede',
        },
        normal: 'Não foram detectadas anormalidades notáveis.',
        normalAll: 'Não foram detectadas anormalidades significativas na função do ombro.',
        conclusion: 'Conclusão',
        analyse: 'Análise: ',
        suggest: 'Sugestão: ',
        cause: 'Por favor, peça conselhos de especialistas para os detalhes.',
    },
    reportTips: {
        title: 'Por favor, entre em contato com o administrador se você quiser excluir o relatório.',
        btnMsg: 'OK',
        alaryTips: 'Este relatório foi excluído pelo administrador. Irá saltar para o próximo relatório!',
    },
    saveQr: {
        title: 'Relatório do Scanner Corporal 3D Visbody',
        btnMsg: 'Pressione longamente a imagem para salvá-la em seu telefone.',
        tips: 'Se você achar que não consegue salvar, vá para [Configurações] e abra as permissões correspondentes.',
    },
    vanCalendar: {
        end: 'End',
        start: 'Start',
        title: 'Calendar',
        startEnd: 'Start/End',
        weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthTitle: function monthTitle(year, month) {
          return year + "/" + month;
        },
        rangePrompt: function rangePrompt(maxRange) {
          return "Choose no more than " + maxRange + " days";
        }
      },
      share: {
        title: 'Por favor, copie o link abaixo e compartilhe.',
        btnMsg: 'Copiar',
      },
    ...ptLocale,
    ...agrZh
}
