/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-11-01 10:24:17
 * @LastEditors: liutq
 * @LastEditTime: 2023-05-23 11:37:57
 */
import fetch from 'unfetch'
import { ApolloLink, from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { apiUrl } from '@/assets/js/config.js'
import router from '@/router'

const httpLink = new HttpLink({
    uri: apiUrl,
    fetch: fetch
})

// 授权白名单
const authWhiteList = [
    'smsLogin',
    'verifyValidationCode',
    'sendValidationCode',
    'register'
]

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (!authWhiteList.includes(operation.operationName)) {
        operation.setContext({
            headers: {
                authorization: `bearer ${window.sessionStorage.getItem('token')}`,
                'accept-language': window.localStorage.getItem('lang') || 'zh-CN'
            }
        })
    }
    return forward(operation)
})

const checkCodeLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        if (response.data.code === 401) {
            // TODO
            // 根据状态码处理
            window.location.href = '/exception/login'
            window.localStorage.removeItem('modelInfo')
            window.localStorage.removeItem('loginInfo')
        }
        try {
            if(response.errors){
                if(response.errors[0].message === 'Unauthorized'){
                    window.localStorage.removeItem('modelInfo')
                    window.localStorage.removeItem('loginInfo')
                    window.sessionStorage.removeItem('token')
                    window.localStorage.removeItem('memberInfo')
                    window.location.href = '/login?register=true'
                }
            } 
        } catch (error) {
            console.log(error)
        }
         
       
        return response
    })
})

// eslint-disable-next-line no-unused-vars
const errorLink = onError(({ networkError, graphQLErrors }) => {    
    // 授权错误是重新登录
    if (graphQLErrors && graphQLErrors[0].message === 'Unauthorized') {
        // TODO
        // 这里跳转到登录重新授权
        window.localStorage.removeItem('modelInfo')
        window.localStorage.removeItem('loginInfo')
        window.localStorage.removeItem('token')
        const currentRoute = router.currentRoute
        console.log('router对象', currentRoute)
        router.push({
            path: '/login',
            query: { redirect: currentRoute.fullPath }
        })
    }
})

export const link = from([authMiddleware, checkCodeLink, errorLink, httpLink])
