/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-11-03 09:53:59
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-08-29 17:04:05
 */
import en from 'element-ui/lib/locale/lang/en'
import agrEn from './agr-en'
export default {
    // 登录页面
    login: {
        loginAndRegister: 'Sign up / Log in',
        registerAndLogin: 'Log in / Sign up',
        userLogin: 'Log In',
        title: 'The account that set up by email and phone number are independently from each other, please',
        mobileLogin: 'Phone',
        emailLogin: 'Email',
        email: 'E-mail',
        loginBtn: 'Log In',
        autoLogin: 'Remember me',
        retransmission: 's',
        formList: [
            {
                label: 'Enter your phone number',
                placeholder: 'Send',
            },
            {
                label: 'Verification Code',
                placeholder: 'Enter verification code',
            },
            {
                label: 'Please enter your email',
            },
        ],
        btn: {
            submit: 'Create Account',
            title: "In order to have the accurate data, it's important to put in the right height, gender and age.",
        },
        rule: {
            phone: {
                requiredMsg: 'Invalid Phone Number Format',
                patternMsg: 'Phone Not Registered , Creat Your Account',
            },
            authCode: {
                requiredMsg: 'Wrong Verification Code, Please Re-Type',
            },
            email: {
                requiredMsg: 'Wrong Email Format ',
                patternMsg: 'Email Not Registered, Create Your Account',
            },
        },
        codeSuccess: 'The verification code has been sent, please check.',
        success: 'Sending Successed',
        error: 'Sending failure',
        loginSuccess: 'Login Successed',
    },
    // 注册页面
    register: {
        mobileRegister: 'Phone',
        emailRegister: 'Email',
        enroll: 'Cellphone Number Already Registered',
        enrollEmail: 'Email is registered',
        search: 'Search',
        title: 'The account that set up by email and phone number are independently from each other, please ',
        needRead: {
            agree: 'I agree',
            privacy: '(Privacy Policy)',
        },
        register: 'Sign In',
        check: "Check ' User Privacy '",
        newUser: 'Sign In',
        form: {
            labelList: ['Name', '* Gender', '* Height', '* Age'],
        },
        placeholder: ['Please enter your name', 'Please enter your height', 'Please enter your age'],
        unit: ['Metric', 'Imperial', ''],
        sexOptions: {
            male: 'Male',
            female: 'Female',
        },
        btn: 'Submit',
        rules: {
            metricheight: ["The height range is 2'3'' - 6'7'', please enter it again."],
            heightMsg: ['The height range is 70 - 200cm, please enter it again.'],
            ageMsg: ['The age range is 10 - 99, please enter it again.'],
            agePointMsg: ['Filled in non-integer: age can only be an integer, please fill in the correct age'],
        },

        success: 'Registration successed',
        userMessage: 'Enter User Name',
        verify: 'Enter 4 Digital Verification Code',
        errorMsg: 'height format does not support decimals, please re-enter',
        abnormalMsg: 'Registration anomaly'
    },
    // 模型合成
    model: {
        loading: 'Loading...Please wait a moment',
        modelLoading: '3D Model Loading',
        tabPane: [
            {
                title: 'Deep Real Sense Technology',
                msg: 'Accurately collect local details of the body through 3D scanning, ensure the millimeter-level accuracy of girth measurement, and clearly present the body shape changes after work-out.',
            },
            {
                title: 'BDA Body Composition Algorithm',
                msg: 'Visbody uses the most advanced body composition measurement system - BDA algorithm to determine body composition. This calculation method based on body volume will more accurately assess the health risks brought by obesity.',
            },
            {
                title: 'Factors that caused errors',
                msg: 'Loose clothing or some decorations on/in the body will cause errors, please wear tights or reduce the amount of clothing on your body if you want to get the most accurate data.',
            },
            {
                title: 'Get your report',
                msg: 'It is recommended to bookmark the web page URL for easy viewing of the report in the future. You can also send this report to your mailbox to save it later.',
            },
        ],
        collect: ['Please bookmark this page, Easy to review the report in the future.', ''],
        know: 'I Got It',
        reminder: [
            'Kindly Remind',
            'Your 3D avatar model has been generated, swiping to left and right to rotate the model.',
            "Your report has been generated and can be viewed by clicking the 'View Report' button.",
            'Your body assessment is unsuccessful, please review the body composition report.',
            'Your body composition was unsuccessful, please review the postural assessment report.',
            'Your measurement is unsuccessful, please go to the device and test again.',
            'The body composition measurement is unsuccessful, please go to the device and test again.',
            'The posture assessment is unsuccessful, please go to the device and test again.',
        ],
        btn: {
            viewReport: 'View Report',
            bodyReport: 'View Body Composition Report',
            postureReport: 'View Body Posture Report',
            lastReport: 'View Last Report',
            loading: 'Loading',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'Download The Report',
            send: 'Send Report',
            report: 'Report',
        },
        reportSuccess: 'Successd!',
        tabPane: {
            labelList: ['Measure Time', 'Item', 'Body Composition', 'Posture Assessment', 'Shoulder Function'],
        },
        sendEmail: 'Email:',
        requiredMsg: 'Enter your email address',
        patternMsg: 'Please enter a vaild email address',
        send: 'Send',
        download: 'Download The Report',
        sendReport: 'Send Report',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'Gender changed successfully',
            height: 'Height changed successfully',
            age: 'Age changed successfully',
        },
        logOut: 'Log Out',
        personal: 'Personal Center',
        cut: 'Change Units/Languages',
        changeSort: 'Product Report Switching',
        describe:
            '* After switching the product model, all measurement reports under the this model will be displayed.',
        describeBtn:
            'After switching the product model, you will be redirected to the latest measurement report under this model.',
        descReport: 'Product Report Switch Successed',
        unit: ['Metric (kg, cm)', 'Imperial (ft, in, lb)'],
        read: {
            reading: 'Read ',
            privacy: '(Privacy Policy)',
        },
        setting: 'Setting',
        form: {
            labelList: ['Phone', 'Units', 'Languages', 'Name', 'Gender', 'Height', 'Age'],
        },
        placeholder: ['Change Your Username', 'New Gender', 'Height Updated', 'Age Updated'],
        btn: {
            cancelBtn: 'Cancel',
            accomplishBtn: 'Complete',
            confirmBtn: 'Comfirm',
        },
        dialog: [
            'Gender is one of the important basis for body composition measurement. The data measured after modification will be different from the previous data. Please confirm if you want to change it?',
            'Height is one of the important basis for body composition measurement. The data measured after modification will be different from the previous data. Please confirm if you want to change it?',
            'Age is one of the important basis for body composition measurement. The data measured after modification will be different from the previous data. Please confirm if you want to change it?',
        ],
        rules: {
            metricheight: ["Please fill in the correct height range (2' 3'' to 6'7'')"],
            heightMsg: ['Please fill in the height within 70cm - 200cm'],
            ageMsg: [
                'Please fill in the age within 10 - 99 years old.',
                'Filled in non-integer ,age can only be an integer, please fill in the correct age',
            ],
        },
        heightMsg: 'Please Re-Choose Height',
        ageMsg: 'Please Re-Choose Age',
        ModelBinding: 'It seems that someone has scanned the code already, please measure agin!',
        ReportEmpty: {
            title: "You don't have any report yet",
            desc: 'Come to Visbody 3D body scanner and test again!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'It seems that someone has scanned the code already, please measure agin!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: "You don't have any report yet",
        desc: 'Come to Visbody 3D body scanner and test again!',
        descS30: 'Come to Visbody-S30 3D body scanner and test again!',
        descM30: 'Come to Visbody-M30 3D body scanner and test again!',
    },
    // 用户信息
    userInfo: {
        height: 'Height:',
        age: 'Age:',
        unit: '',
        weight: 'weight'
    },
    // 结论
    assessment: {
        great: ' great',
        good: ' good',
        normall: 'normall',
        weak: ' weak',
    },
    // 体成分
    mass: {
        title: 'Body Composition Assessment Overview',
        titles: 'Body Composition',
        springFrame: '',
        massFrame: 'No measurement data for the day',
        contrast: 'Choose a report to compare',
        contrastReport: 'Choose a report to compare',
        noRecord: 'No records',
        score: 'Score',
        status: 'Your current body composition status ',
        WT: 'weight',
        PBF: 'body fat percentage',
        endSymbol: '.',
        measure: {
            value: 'Values',
            standardRange: 'Standard range',
            comparedWithLastLime: 'Net',
            comparedNet: 'Compared To The Last Score',
            current: 'Current Measurement Score',
            WT: 'Weight',
            FFM: 'Lean Body Mass',
            BFM: 'Body Fat Mass',
            LM: 'Muscle Mass',
            TBW: 'Body Water',
            SM: 'SMM (Skeletal Muscle Mass)',
            PROTEIN: 'Protein',
            TM: 'Inorganic Salts',
            BMR: 'Basal Metabolism Rate',
            WHR: 'WHR (Waist-Hip Ratio)',
            BMI: 'BMI',
            PBF: 'BFP (Body Fat Percentage)',
            VFG: 'Visceral Fat Level',
            unit: 'kg',
            BMRUnit: 'kcal/d',
        },
        explain: [
            {
                title: 'Weight',
                msg: 'Weight is the sum of body water, protein, inorganic salt and body weight.',
            },
            {
                title: 'Lean Body Mass',
                msg: 'Lean Body Mass is the total body weight without fat.',
            },
            {
                title: 'Body Fat Mass',
                msg: 'Body Fat mass is the sum of subcutaneous fat, visceral fat and muscle fat.',
            },
            {
                title: 'Muscle Mass',
                msg: 'Soft lean mass is the lean body mass, which includes skeletal muscle, smooth muscle, and cardiac muscle.',
            },
            {
                title: 'Body Water',
                msg: 'Most of the human body is water with an amount of 50%-70% of body weight. And body water is mainly in human cells and body fluids, most of which is in muscle cells.',
            },
            {
                title: 'SMM',
                msg: 'Skeletal muscle mass, also known as striated muscle, is a type of muscle attached to bones. This data contains the amount of Skeletal Muscle.',
            },
            {
                title: 'Protein',
                msg: 'Protein is a solid substance with ammonia, which exists in all cells of the human body. It is the main component of muscle mass.',
            },
            {
                title: 'Inorganic Salts',
                msg: 'The human body is composed of organic matter, inorganic matter and water. The inorganic matter here is inorganic salts which amounts to 5% of the body weight.',
            },
            {
                title: 'Basal Metabolism Rate',
                msg: 'Basal Metabolism rate is the total energy consumed in a day when the body is at rest, not affected by exercise, physical objects, nervousness, external temperature changes, etc.',
            },
            {
                title: 'WHR (Waist-Hip Ratio)',
                msg: 'The ratio of waist to hip circumference, it is an important indicator for determining central obesity.',
            },
            {
                title: 'BMI',
                msg: 'BMI is mainly used to assess the appearance of obesity, and it is a common standard for measuring body fatness.',
            },
            {
                title: 'BFP (Body Fat Percentage)',
                msg: 'BFP is a measurement of body composition telling how much of the body weight is fat.',
            },
            {
                title: 'Visceral Fat Level',
                msg: 'Visceral fat is an important indicator to evaluate the hidden obesity.',
            },
            {
                title: 'ICW',
                msg: 'ICW: It is  the body fluid contained in the body cells and is the basic component of the  protoplasm.',
            },
            {
                title: 'ECW',
                msg: 'ECW: Usually  refers to extracellular body fluids, including plasma and interstitial fluid  between blood vessels and tissue cells.',
            },
            {
                title: 'Metabolic Age',
                msg: 'Metabolic age refers to the performance of the body and its metabolism-related health functions.',
            },
        ],
        segment: {
            fat: 'Segmental Fat Analysis',
            muscle: 'Segmental Lean Analysis',
            right: 'Right',
            left: 'Left',
            standard: 'Normal',
            lowStandard: 'Under',
            superStandard: 'Over',
        },
    },
    // 体态
    shape: {
        title: 'Body Assessment Overview',
        titles: 'Posture Assessment',
        status: 'Your current physical condition ',
        suggest: 'Suggestion',
        possibility: ', there is a possibility of ',
        models: {
            front: 'Front',
            left: 'Left Side',
            right: 'Right Side',
            top: 'Back',
            low: ' Under',
            high: ' Over',
            normal: 'Normal',
        },
        item: {
            deviate: 'Values',
            result: ' Explaination',
            normal: 'Normal',
            abnormal: 'Abnormal',
            head: 'forward head posture',
            headSlant: 'head tilt',
            roundShoulderLeft: 'rounded shoulders posture(left side)',
            roundShoulderRight: 'rounded shoulders posture(right side)',
            highLowShoudler: 'uneven shoulders',
            pelvis: 'Pelvic forward/Pelvic posterior displacement',
            leftKneeCheck: 'left knee evaluation ',
            rightKneeCheck: 'right knee evaluation',
            leg: 'leg shape',
            leftLeg: 'Left leg:',
            rightLeg: 'Right leg:',
        },
        explain: [
            {
                title: 'Forward Head Posture',
                msg: 'The angle between the ear point on the left side and the line connecting the center of the neck and the median line on the side',
            },
            {
                title: 'Head Tilt',
                msg: 'The angle between the midpoint of the front head and the center of the neck and the midline of the front',
            },
            {
                title: 'Rounded Shoulders Posture(left side)',
                msg: 'The angle between the line connecting the highest point on the left side of the back and the tangent line of the shoulder',
            },
            {
                title: 'Rounded Shoulders Posture(right side)',
                msg: 'The angle between the line connecting the highest point on the right side of the back and the tangent to the shoulder',
            },
            {
                title: 'Uneven Shoulders',
                msg: 'The vertical distance between the left and right shoulder points on the back',
            },
            {
                title: 'Pelvic forward/Pelvic posterior displacement',
                msg: 'The included angle of the line connecting the center point of the neck, the hip bone point, and the ankle point on the left side',
            },
            {
                title: 'Left Knee Evaluation',
                msg: 'The included angle of the three-point line connecting the hip bone point, knee joint, and ankle point on the left side',
            },
            {
                title: 'Right Knee Evaluation',
                msg: 'The included angle of the three-point line connecting the hip bone point, knee joint, and ankle point on the right side',
            },
            {
                title: 'Leg Shape',
                msg: 'The included angle of the line connecting the hip bone point, knee joint, and ankle point on the front of the left and right legs',
            },
        ],
        exception: [
            {
                title: 'Inclined to Left Side',
                abnormal: 'inclined to left side',
            },
            {
                title: 'Inclined to Right Side',
                abnormal: 'inclined to right side',
            },
            {
                title: 'Left High',
                abnormal: 'left high',
            },
            {
                title: 'Right High',
                abnormal: 'right high',
            },
            {
                title: 'Posterior Pelvic Tilt',
                abnormal: 'posterior pelvic tilt',
            },
            {
                title: 'Anterior Pelvic Tilt',
                abnormal: 'anterior pelvic tilt ',
            },
            {
                title: 'Hyperextension of the Left Knee',
                abnormal: 'hyperextension of the left knee',
            },
            {
                title: 'Left Knee Forward Bending',
                abnormal: 'left knee forward bending',
            },
            {
                title: 'Hyperextension of The Right Knee',
                abnormal: 'hyperextension of the right knee',
            },
            {
                title: 'Right Knee Forward Bending',
                abnormal: 'right knee forward bending',
            },
            {
                title: 'K-shaped Legs',
                abnormal: 'k-shaped legs',
            },
            {
                title: 'D-shaped Legs',
                abnormal: 'd-shaped legs',
            },
            {
                title: 'X-shaped Legs',
                abnormal: 'x-shaped legs',
            },
            {
                title: 'O-shaped Legs',
                abnormal: 'o-shaped legs',
            },
        ],
    },
    // 体围
    girth: {
        title: 'Circumferences',
        present: '(Present)',
        noHistory: 'No data.',
        tpl: [
            {
                title: 'Neck circumference',
                key: 'neckGirth',
            },
            {
                title: 'Left upper arm',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'Right upper arm',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'Chest',
                key: 'bustGirth',
            },
            {
                title: 'High Waist',
                key: 'waistGirth',
            },
            {
                title: 'Mid Waist',
                key: 'midWaistGirth',
            },
            {
                title: 'Hipline',
                key: 'hipGirth',
            },
            {
                title: 'Left thigh',
                key: 'leftThighGirth',
            },
            {
                title: 'Left thigh middle',
                key: 'leftMidThighGirth',
            },
            {
                title: 'Minimum circumference of left thigh',
                key: 'leftMinThighGirth',
            },
            {
                title: 'Right thigh',
                key: 'rightThighGirth',
            },
            {
                title: 'Right thigh middle',
                key: 'rightMidThighGirth',
            },
            {
                title: 'Minimum circumference of right thigh',
                key: 'rightMinThighGirth',
            },
            {
                title: 'Left calf circumference',
                key: 'leftCalfGirth',
            },
            {
                title: 'Right calf circumference',
                key: 'rightCalfGirth',
            },
            {
                title: 'Low waist',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'Shoulder Function',
        model: {
            val: 'Values',
            scope: 'Maximum Activity Location',
            diff: 'Net',
        },
        normal: 'Notable abnormalities not detected',
        normalAll: 'No significant abnormalities in shoulder function have been detected',
        conclusion: 'Conclusion',
        analyse: 'Analysis:',
        suggest: 'Suggestion:',
        cause: 'Please ask advices from experts for the details.',
    },
    reportTips: {
        title: 'Please contact the administrator if you want to delete the report.',
        btnMsg: 'OK',
        alaryTips: 'This report has been deleted by the administrator. Will jump to the next report!',
    },
    saveQr: {
        title: 'Visbody 3D Body Scanner Report',
        btnMsg: 'Long press the image to save it to your phone.',
        tips: 'If you find that you cannot save, please go to [Settings] and open the corresponding permissions.',
    },
    share: {
        title: 'Please copy the link below and share it.',
        btnMsg: 'Copy',
    },
    ...en,
    ...agrEn
}
